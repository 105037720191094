import { MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { Dispatch, Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ctrlKey } from "../utils/system";
import clsx from "clsx"
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { IpResults, useHandleIpResults } from "./IpSearch";
import { DocumentResults, useHandleDocumentResults } from "../documents/DocumentSearch";
import { useRoles } from "../user/Auth";

type SearchType = 'ip' | 'document' | undefined

export default function SearchButton({defaultSearchType}: {defaultSearchType: SearchType}) {
    const {t} = useTranslation()
    const [searchType, setSearchType] = useState(undefined as SearchType)

    const buttonStyle = "border-2 border-transparent hover:border-gray-300 rounded-md block text-gray-300 hover:text-gray-500 cursor-pointer group"
    //console.log({isSearching})

    useEffect(() => {
        function handleCmdK(event) {
            if (event.code === 'KeyK' && (event.metaKey || event.ctrlKey)) {
                event.preventDefault() // firefox
                setSearchType(defaultSearchType)
            }
        }
        document.addEventListener(`keydown`, handleCmdK)
        return () => document.removeEventListener(`keydown`, handleCmdK)
    }, [defaultSearchType])


    return <>
        <button onClick={() => setSearchType(defaultSearchType)}
            className={clsx("flex flex-row gap-2 items-center text-left px-2 py-1", buttonStyle)}
        >
            <MagnifyingGlassIcon className="h-5 w-5 inline" />
            <span>{t('search')}...</span> 
            <span className="text-sm"><Key>{ctrlKey}</Key><Key>K</Key></span>
        </button>
        {searchType && <SearchDialog {...{searchType, setSearchType}} />}
    </>
}

function Key({children}: {children: any}) {
    return <kbd className="max-sm:hidden font-medium border group-hover:border-gray-300 px-1.5 ml-1 rounded shadow-sm">{children}</kbd>
}


function SearchDialog({searchType, setSearchType}: {searchType: SearchType, setSearchType: Dispatch<SearchType>}) {
    const {t} = useTranslation()
    const {hasDocuments} = useRoles()

    const {handleIpResult} = useHandleIpResults()
    const {handleDocumentResult} = useHandleDocumentResults()

    const [query, setQuery] = useState('')
    const _query = query.replace(/^[#/]/, '')

    const onChange = searchType === 'ip' ? handleIpResult : handleDocumentResult

    return (
        <Transition.Root show={searchType !== undefined} as={Fragment} afterLeave={() => setQuery('')} appear>
            <Dialog as="div" className="relative z-30" onClose={() => setSearchType(undefined)}>
                {/* Gray Overlay */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:pt-10 md:pb-6 xl:pt-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-70 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
                            <Combobox onChange={result => {
                                // @ts-ignore
                                onChange(result)
                                setSearchType(undefined);
                            }}>
                                <div className="relative">
                                    <MagnifyingGlassIcon
                                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                                        aria-hidden="true"
                                    />
                                    <Combobox.Input
                                        // TODO: check focus on Chrome -> Actually it hightlights the whole relative div
                                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-sm"
                                        placeholder="Search..."
                                        value={query}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <button
                                        title={t('close')} onClick={() => setSearchType(undefined)}
                                        className="absolute right-2 top-2 font-semibold text-2xs border rounded-lg shadow-sm px-1.5 py-2"
                                    >
                                        ESC
                                    </button>
                                </div>

                                {/* Query must be converted to lower case in the Results */}
                                {searchType === 'ip' 
                                    ? <IpResults query={_query} />
                                    : searchType === 'document'
                                    ? <DocumentResults query={_query} />
                                    : null}

                                {hasDocuments &&
                                    <TypeFooter  {...{
                                        searchType, setSearchType,
                                        query, setQuery,
                                    }} />}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

const typeCommands = [
    { type: 'ip', key: '/', description: 'to search for IPs' },
    { type: 'document', key: '#', description: 'to search for Documents' },
] as { type: SearchType, key: string, description: string }[]

function TypeFooter(
    { searchType, setSearchType, query, setQuery }:
        { searchType: SearchType, query: string, setSearchType: Dispatch<SearchType>, setQuery: Dispatch<string> }) {

    const { t } = useTranslation()

    useEffect(() => {
        const newType = typeCommands.find(c => query.startsWith(c.key) && c.type !== searchType)
        if (newType)
            setSearchType(newType?.type)
    }, [query, setSearchType, searchType])

    return (
        <div className="flex flex-wrap items-center gap-4 bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
            {typeCommands.map(({ type, key, description }, i) =>
                <button key={i} onClick={() => {
                    setQuery(key + query.replace(/^[#/]/, ''))
                }}
                    className="px-1 flex flex-row gap-1 sm:gap-2 items-center"
                >
                    <kbd
                        className={clsx(
                            'flex h-5 w-5 items-center justify-center rounded shadow-sm border bg-white font-semibold',
                            searchType === type ? 'border-pcx-400 text-pcx-500' : 'border-gray-300 text-gray-500',
                        )}
                    >
                        {key}
                    </kbd> {t(description)}
                </button>
            )}
        </div>
    )
}