
// TODO: Maybe create a Modal for all post-edit actions:
//  - Renaming of folders
//  - Updating with DM
//  - Stopping payment handling
//  - Initiating payment handling
//const referenceChangedStr = 'reference-changed'

import { Link, useLocation, useNavigate } from "react-router-dom"
import { useRoles } from "../../user/Auth"
import { useTranslation } from "react-i18next"
import { useDennemeyer, useIpRight, useSynchronize } from "../../renewal/DennemeyerProvider"
import { useDocumentSettings } from "../../documents/DocumentsSettings"
import { useAgorumObject, useFindUuid } from "../../documents/backend"
import { useState } from "react"
import Modal from "../Modal"
import { Field, Form, Formik } from "formik"
import { IconSpinner } from "../icons"
import _ from "lodash"
import { useMember } from "../../patents/FamilyMember"
import { family_member } from "../../data"
import { AfterUpdateAction, afterUpdateActionsStr } from "."
import { Member } from "../../patents/patents"
import { DmParams, toQueryParams } from "../../renewal/MemberOverview"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid"
import { isImported, renewalStatusExtraction } from "../../renewal/states"

export function MemberChangedModal() {
  const {hasAnnuities, hasDocuments} = useRoles()

  const location = useLocation()
  const {family, member} = useMember() // TODO remove

  if (!hasAnnuities && !hasDocuments)
    return null

  const state = location.state ?? {}
  //const state = {
  //  [afterUpdateActionsStr]: ['update-reference', 'move-folder', 'initiate-payment-handling', 'stop-payment-handling'] as AfterUpdateAction[],
  //  to: member,
  //  from: {...member, internalReference: 'Old Reference'},
  //}

  const changes = state[afterUpdateActionsStr] ?? []
  if (changes.length === 0 || member.familyMemberId === undefined)
    return null

  const {from, to} = state

  return <RefenceChangedForm {...{changes, from, to, hasAnnuities, hasDocuments}} />
}

function RefenceChangedForm(
    {changes, from, to, hasAnnuities, hasDocuments}: 
    {changes: AfterUpdateAction[], from?: Member, to: Member, hasAnnuities: boolean, hasDocuments: boolean}
) {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const { member, family } = useMember()
    const { ipRightByMemberId, postIpRight, isIgnored } = useDennemeyer()
    const pcIpRight = ipRightByMemberId?.[member.familyMemberId]

    const { ipRight: ipRightResp } = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data
    const renewalStatus = renewalStatusExtraction({ member: to, pcIpRight, ipRight, isIgnored })
    const imported = isImported(renewalStatus.status, ipRight)

    const { triggerSynchronize } = useSynchronize()

    function updateReference() {
        triggerSynchronize([pcIpRight.ipRightId])
    }

    async function stopCaseWithDm() {
        if (pcIpRight) {
            await postIpRight({ ...pcIpRight, status: 'Inactive' })
            await triggerSynchronize([pcIpRight?.ipRightId])
        }
    }

    const { number } = useDocumentSettings()
    const { uuid } = useFindUuid(number, family_member, from?.internalReference, family.internalReference)
    const { object, update } = useAgorumObject({ uuid })

    const hasFolder = uuid !== undefined
    // console.log({ hasFolder, object, from, uuid })

    async function moveFolder() {
        if (uuid === object.uuid)
            return update({ ...object, name: to.internalReference })
    }

    const [errors, setErrors] = useState({})

    // TODO only update/stop if imported
    const actions = changes.filter(c =>
        (c === 'move-folder' && hasDocuments && hasFolder) ||
        (c === 'initiate-payment-handling' && hasAnnuities) ||
        (c === 'stop-payment-handling' && hasAnnuities && imported) ||
        (c === 'update-reference' && hasAnnuities && imported)
    )

    const labels = {
        'initiate-payment-handling': 'Initate payment handling',
        'stop-payment-handling': 'Stop payment handling',
        'move-folder': 'Rename the folder',
        'update-reference': 'Update the reference with the payment handler',
    }

    const initialValues = _(actions).map(a => [a, true]).fromPairs().value()

    const params: DmParams = {
        familyMemberId: member.familyMemberId,
        ipRightId: pcIpRight?.ipRightId,
        dennemeyerId: pcIpRight?.dennemeyerId,
    }

    // TODO: Indicate successes and errors


    async function onSubmit(values) {

        async function workOnAction(action: AfterUpdateAction, callback: () => Promise<void>) {
            if (values[action]) {
                try {
                    await callback()
                    setErrors(e => ({ ...e, [action]: 0 }))
                } catch (error) {
                    console.warn(error)
                    setErrors(e => ({ ...e, [action]: error }))
                }
            }
        }

        //setIsSubmitting(true)
        setHasSubmitted(true)

        await workOnAction('stop-payment-handling', async () => {
            stopCaseWithDm()
        })
        await workOnAction('move-folder', async () => {
            await moveFolder()
        })
        await workOnAction('update-reference', async () => {
            updateReference()
        })
        await workOnAction('initiate-payment-handling', async () => {
            // do nothing; just flag for manual initiation
        })
    }

    const changesStr = [
        from !== undefined && to !== undefined && from?.internalReference !== to.internalReference && `Reference changed from ${from.internalReference} to ${to.internalReference}`,
        changes !== undefined && (changes.includes('initiate-payment-handling') || changes.includes('stop-payment-handling')) && 'Patent Status has Changed',
    ].filter(Boolean)

    const doInitiatePaymentHandling = errors['initiate-payment-handling'] === 0
    const initiateLink = `renewals?${toQueryParams(params)}`

    return (
        <Modal escAction={() => navigate('.')}>
            <div className="p-4 pb-2 bg-white max-w-xl">
                <h2 className="mb-6">Relevant informtion has changed for {to.internalReference}</h2>
                {/* TODO Make text based on actions */}
                <p className="text-slate-800">The following changes relevant for other parts have been detected</p>
                <ul className="mb-8 pl-2">
                    {changesStr.map((c, i) => c && <li key={i} className="text-slate-800">{c}</li>)}
                </ul>
                <p>Do you thus want to perform the following updates?</p>
            </div>
            <Formik enableReinitialize {...{ initialValues, onSubmit }}>{({ values }) =>
                <Form className="">
                    {actions.map((action) =>
                        <label key={action} className="flex flex-row items-center gap-2 px-4 py-1">
                            <Field type="checkbox" className="form-checkbox peer" name={action} />
                            <span className="peer-checked:text-pcx-800 text-slate-500">{labels[action]}</span>
                            {values[action] && (
                                action === 'initiate-payment-handling'
                                    ? (hasSubmitted && <span className="text-slate-500">Manually initiate by clicking the button</span>)
                                    : <ProgressIndicator {...{ hasSubmitted, error: errors[action] }} />)}
                        </label>
                    )}
                    <div className="mt-4 flex flex-row-reverse gap-4 p-4 bg-pcx-200">
                        {hasSubmitted
                            ? <>
                                {doInitiatePaymentHandling && <Link
                                    className="btn-primary" 
                                    to={initiateLink}
                                >{t('initiate-payment-handling')}</Link>}
                                <Link
                                    className={doInitiatePaymentHandling ? "btn-secondary" : "btn-primary"} 
                                    to="."
                                >{t('close')}</Link>
                            </>
                            : <>
                                <button type="submit" className="btn-primary flex flex-row gap-2 items-center">
                                    Perform Updates {/*isSubmitting && <IconSpinner className="h-4 w-4 animate-spin" />*/}
                                </button>
                                <button type="button" className="btn-secondary" onClick={() => navigate('.')}>{t('dismiss')}</button>
                            </>}
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

function ProgressIndicator({hasSubmitted, error}: {hasSubmitted: boolean, error: number | undefined | any}) {
    if (!hasSubmitted)
        return null
        
    return error === undefined
        //? <div className="size-4 border-2 border-slate-500 rounded-full" />
        ? <IconSpinner className="size-4 animate-spin text-pcx-500" />
        : error === 0
        ? <CheckCircleIcon className="size-5 text-lime-500" />
        : <ExclamationCircleIcon className="size-5 text-warn-500" title={typeof error === 'string' ? error : undefined} />
}