import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'

import { Agent } from './utils'
import Modal from '../components/Modal'
import { useBackend } from "../BackendProvider"
import { EditFormikMask } from '../components/edit-table'
import { personFields, companyFields, personValidation, companyValidation } from '../data'
import { nameOf } from './utils'


export function AddAgentMask({title, withPersons = true, withCompanies = true, setShowMask, update}) {
  const {t} = useTranslation()
  const {entityOperation, agents} = useBackend()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [warning, setWarning] = useState(undefined)

  function isAllowed(agent: Agent) {
    return (agent.agentType === 'person' && withPersons) || 
           (agent.agentType === 'company' && withCompanies)
  }

  const agentsList = new Fuse(
    agents.filter(a => isAllowed(a)).map(nameOf), {
      threshold: 0.1,
    })

  function forceSubmit(values: Agent) {
    entityOperation("agent", "add", values)
      .then(({agentId}: Agent) => update(agentId))
      .then(() => setShowMask(false))
  }

  function onSubmit(values: Agent) {
    const possibleOverlaps = agentsList.search(nameOf(values)).map(a => a.item)
    if (possibleOverlaps.length > 0)
      setWarning({warning: `Possible overlap with: ${possibleOverlaps.join("; ")}`, values})
    else
      forceSubmit(values)
  }


  let editPerson = undefined
  if (withPersons) {
    const persons = agents.filter(a => a.agentType === 'person')
    editPerson = <EditFormikMask {...{title: t('create-new-person'), fields: personFields, validations: personValidation(persons), onSubmit, cancelAction: () => setShowMask(false) }} />
  }

  let editCompany = undefined
  if (withCompanies) {
    const companies = agents.filter(a => a.agentType === 'company')
    editCompany = <EditFormikMask {...{title: t('create-new-company'), fields: companyFields, validations: companyValidation(companies), onSubmit, cancelAction: () => setShowMask(false) }} />
  }

  return (
    <Modal escAction={() => setShowMask(false)}>
      <h2 className="text-center pb-1 pt-2">{title ? title : ''}</h2>
      {editCompany}
      {editPerson}
      {warning && <div className="p-4 flex flex-col gap-2">
        <div className="text-red-700">{warning.warning}</div>
        <button className="btn-warn self-end" onClick={() => forceSubmit(warning.values)}>Save anyway</button>
      </div>}
    </Modal>
  )
}