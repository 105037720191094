import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { Trans } from "react-i18next"
import _ from 'lodash'

import { useProducts } from "./ProductsProvider";
import Modal from '../components/Modal';
import { Formik, Form, Field, useField } from 'formik';
import { useMessages } from '../Messages';
import { countryCodes } from "../data";
import { ToggleButtonField } from '../components/ToggleButton';
import { saveCrudLinks, saveLinks, useBackend } from '../BackendProvider';
import { useRoles } from '../user/Auth';
import { TagListField } from '../components/TagList';
import { Commodity, commodityUrl, useProductMapping } from './products';
import { BreadCrumbs } from "../components/BreadCrumbs";
import Image from "../components/Image";
import { IconEdit } from "../components/icons";
import DeleteButton from "../components/DeleteButton";
import clsx from "clsx";
import { familyUrl } from "../patents/utils";
import { useTrademarks } from "../trademarks/TrademarksProvider";
import { trademarkFamilyUrl } from "../trademarks/utils";
import ProtectionIcon from "../components/ProtectionIcon";
import { FocusedBrowser } from "../documents/FocusedBrowser";

export default function ProductView() {
  const { t } = useTranslation();
  // TODO: add flag for product tags
  const { isEditUser, hasProductCountries, isDev, hasDocuments } = useRoles()

  const { tagsLookup } = useBackend()
  const { deleteCommodity, reload, commodityById, commodityCountryLinksByCommodityId } = useProducts()
  const { id: _id } = useParams();
  const id = parseInt(_id);

  const commodity = commodityById[id]

  if (!commodity) return null

  const commodityCountryLinks = commodityCountryLinksByCommodityId[commodity.commodityId] ?? []
  const countryTags = commodityCountryLinks.map((l) => l.countryCode);
  const tagsOfProduct = tagsLookup?.["commodity"]?.[commodity.commodityId] ?? []

  const ribbonStyle = clsx('ribbon', commodity.status === "active" && "bg-pcx-200")

  // TODO: documents
  return <>
    {/* @ts-ignore */}
    <Helmet>
      <title>{commodity.commodityReference} | {commodity.commodityClass ?? '-'} | {t('products')} | Patent Cockpit</title>
    </Helmet>
    <div>
      <BreadCrumbs parts={[
        { to: '/products/portfolio', label: t('products') },
        <ProductBreadCrumb key={commodity.commodityReference} {...{ commodity }} />,
      ]} />
    </div>
    <div className="px-4">
      <div className={clsx(
        "p-2 pt-4 bg-white rounded-xl shadow grid grid-cols-1 md:grid-cols-[1fr_2fr_1fr] gap-x-2",
        hasDocuments ? "max-w-screen-lg 2xl:max-w-screen-xl" : "max-w-screen-lg"
      )}>
        <div className="px-2 md:col-span-3 grid grid-cols-subgrid gap-3">
          <Image  {...{
            entity: "commodity",
            entityId: commodity.commodityId,
            isEditable: isEditUser,
          }} />

          <div>
            <p>
              {commodity.commodityDescription ?? ''}
            </p>
            <div className="flex flex-wrap gap-2 text-sm text-gray-500">
              {isDev &&
                _(tagsOfProduct)
                  .uniq()
                  .sortBy((t) => t)
                  .map((t) => <div key={t}>{t}</div>)
                  .value()}
            </div>
          </div>

          <div className="flex flex-row gap-1 justify-between">
            <div className="flex items-start flex-wrap gap-1 text-sm">
              <div className={ribbonStyle}>{t(commodity.status)}</div>
              {commodity.isThirdParty && (
                <div className={ribbonStyle}>{t("isThirdParty")}</div>
              )}
              {(hasProductCountries ? countryTags : []).map((cc) => (
                <div key={cc} className={ribbonStyle}>
                  {cc}
                </div>
              ))}
            </div>
            {isEditUser &&
              <div className="flex flex-row gap-1">
                <Link
                  to="edit"
                  className="btn-primary h-5 w-5 p-px"
                  title={t("edit")}
                >
                  <IconEdit className="" />
                </Link>
                <DeleteButton
                  className="btn-warn h-5 w-5 p-px"
                  deleteAction={() => deleteCommodity(commodity).then(() => reload())}
                />
              </div>}
          </div>
        </div>
        <div className="p-2 md:col-span-3 grid grid-cols-subgrid border-t-2 mt-2 border-pcx-200">
          <div>
            <ProductPatentMappingDetails {...commodity} />
          </div>
          <div>
            <ProductTrademarkMappingDetails {...commodity} />
          </div>
        </div>
        {hasDocuments && <div className="p-2 md:col-span-3 border-t-2 mt-2 border-pcx-200">
          <FocusedBrowser entity="commodity" internalReference={commodity.commodityReference} parent={commodity.commodityClass} parentName={commodity.commodityClass} />
        </div>}
      </div>
    </div>
    <Outlet />
  </>
}

function ProductBreadCrumb({commodity}: {commodity: Commodity}) {
  return (
    <Link to={commodityUrl(commodity)}>
      {commodity.commodityClass && <span className="hidden lg:inline mr-1 text-pcx-500">{commodity.commodityClass} /</span>}
      <span>{commodity.commodityReference}</span>  
    </Link>
  )
}



export function PostProduct({ isAdding = false }) {
  const { isDev, isEditUser, hasProductCountries } = useRoles();

  const { t } = useTranslation();
  const {
    tags,
    tagsLookup,
    linkOperation,
  } = useBackend();
  const { commodities, commodityByReference, postCommodity, commodityCountryLinks: _commodityCountryLinks, postCommodityCountryLink, deleteCommodityCountryLink } = useProducts();
  const { setErrorMessage } = useMessages();

  const { id: _id } = useParams();
  const id = parseInt(_id);
  const navigate = useNavigate();

  const commodityCountryLinks =
    !isAdding && hasProductCountries
      ? _commodityCountryLinks.filter((l) => l.commodityId === id)
      : [];

  const countryTags = commodityCountryLinks.map((l) => l.countryCode);
  const tagsOfProduct = tagsLookup["commodity"]?.[id] ?? [];
  const allTags = _.uniq(tags.map((t) => t.tag));

  const commodity = commodities.find((c) => c.commodityId === id);

  const existingClasses = _.uniq(
    commodities
      .map((c) => c.commodityClass)
      .filter((c) => c !== "" && c !== undefined)
  );

  const initialValues: Commodity & {tags?: string[], countryCodes?: string[]} = isAdding
    ? {
        commodityId: undefined,
        commodityClass: undefined,
        commodityReference: "",
        commodityDescription: "",
        status: "active",
        isThirdParty: false,
        tags: [],
        countryCodes: [],
      }
    : commodity;

  const handleSubmit = isAdding
    ? (c) =>
      postCommodity(c)
        .then((withId) =>
          postCommodityCountryLink((c.countryCodes ?? []).map((cc) => asLink(cc, withId.commodityId)))
            .then(() =>
              linkOperation(
                "tag",
                "bulk-add",
                (c.tags ?? []).map((tag) => asTag(tag, withId.commodityId))
              )
            )
        )
        .then(() => linkOperation("tag", "get"))
    : (newCommodity) =>
      postCommodity(newCommodity)
        .then(() =>
          saveLinks(
            tagsOfProduct.map((tag) => asTag(tag, newCommodity.commodityId)),
            (newCommodity.tags ?? []).map((tag) =>
              asTag(tag, newCommodity.commodityId)
            ),
            linkOperation,
            "tag"
          )
        )
        .then(() =>
          saveCrudLinks(
            countryTags.map((cc) => asLink(cc, commodity.commodityId)),
            (newCommodity.countryCodes ?? []).map((cc) =>
              asLink(cc, commodity.commodityId)
            ),
            postCommodityCountryLink,
            deleteCommodityCountryLink,
          )
        );

  return (
    <Modal>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>
          handleSubmit(values)
            .then(() => navigate("/products/portfolio"))
            .catch((err) => setErrorMessage(err.message))
        }
        enableReinitialize
        validate={(values) => {
          const errors = {};
          if (values.commodityReference in commodityByReference) {
            const c = commodityByReference[values.commodityReference]
            if (c.commodityId !== values.commodityId) {
              errors['commodityReference'] = c.status === 'stopped'
                ? t("is-already-used-what", { what: t("stopped-product") })
                : t("is-already-used");
            }
          }
          return errors;
        }}
      >
        <Form>
          <div className="p-4 grid grid-cols-2 gap-3">
            <h2 className="col-span-2">
              {isAdding ? (
                t("add-product")
              ) : (
                <Trans
                  i18nKey="edit-reference"
                  values={{ reference: commodity?.commodityReference }}
                />
              )}
            </h2>
            {/* TODO: add existing classes as proposal */}
            <RichField
              className="form-input"
              labelClassName="col-span-2 sm:col-span-1"
              name="commodityClass"
              proposals={existingClasses}
            />
            <RichField
              className="form-input"
              labelClassName="col-span-2 sm:col-span-1"
              name="commodityReference"
              autoFocus
            />
            <RichField
              className="form-input h-48 w-full"
              labelClassName="col-span-2"
              name="commodityDescription"
              as="textarea"
            />

            <RichField className="form-select" name="status" as="select">
              {["active", "stopped", "planned"].map((s) => (
                <option key={s} value={s}>
                  {t(s)}
                </option>
              ))}
            </RichField>

            <label className="pl-0.5">
              <div className="label -ml-0.5 mb-1">{t("isThirdParty")}</div>
              <ToggleButtonField name="isThirdParty" />
            </label>

            {isDev && (
              <label className="col-span-2 sm:col-span-1">
                <div className="label mb-1">{t("tags")}</div>
                <TagListField name="tags" availableTags={allTags} />
              </label>
            )}
            {hasProductCountries && (
              <label className="col-span-2 sm:col-span-1">
                <div className="label mb-1">{t("countryCode")}</div>
                <TagListField
                  name="countryCodes"
                  placeholder={t("countryCode")}
                  availableTags={countryCodes}
                />
              </label>
            )}
          </div>
          <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
            {isEditUser && (
              <button id="save" className="btn-primary" type="submit">
                {t("save")}
              </button>
            )}
            {/* @ts-ignore */}
            <Link className="btn-secondary" to={-1 }>
              {t("cancel")}
            </Link>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
}

function asTag(tag, entityId) {
  return { tag, entity: "commodity", entityId };
}


function RichField({children = null, labelClassName = '', ...props }) {
    const {t} = useTranslation()
    // eslint-disable-next-line
    const [field, meta] = useField(props as {name: string})
    const { name } = props
    return (
        <label className={labelClassName}>
            <div className='label mb-1'>{t(name)}</div>
            <Field id={name} {...{...props, list: props?.proposals && `${name}s`}} >
                {children}
            </Field>
            {meta.touched && meta.error && <div className='text-red-500 mt-1 ml-1'>{meta.error}</div>}
            {props?.proposals &&
                <datalist id={`${name}s`}>
                    {_(props.proposals).sortBy(p => p.toLowerCase()).map(p => <option key={p} value={p} />).value()}
                </datalist>}
        </label>
    )
}

const asLink = (countryCode: string, commodityId: number) => ({ countryCode, commodityId });

function ProductPatentMappingDetails({commodityId, isThirdParty}: Commodity) {
    const {t} = useTranslation()
    const {familiesByCommodityId} = useProductMapping()

    const families = familiesByCommodityId[commodityId] ?? []

    return <>
        <div className="mb-1 flex flex-row items-center gap-8">
            <h4>
                {t('patents')}
            </h4>
            <Link to="patents" className="btn-secondary h-5 w-5 p-px"><IconEdit className="" /></Link>
        </div>
        <ul className="list-none">
            <li className="hidden last:block text-slate-500">{t("no-overlapping-patents")}</li>
            {_(families)
                .sortBy(f => f.internalReference)
                .map(family => <li key={family.patentFamilyId}>
                    <Link className="underline-link flex flex-row gap-1 items-center" to={familyUrl(family)}>
                        <ProtectionIcon {...{ isThirdParty }} /> {family.internalReference}
                    </Link>
                </li>)
                .value()}
        </ul>
    </>
}

function ProductTrademarkMappingDetails({commodityId, isThirdParty}: Commodity) {
    const {t} = useTranslation()
    const {hasBrands} = useRoles()
    const {trademarkProductMappingsByCommodityId, trademarkFamilyById} = useTrademarks()

    const families = (trademarkProductMappingsByCommodityId[commodityId] ?? [])
        .map(m => ({...m, ...trademarkFamilyById[m.familyId]}))
        .filter(m => m.protected)

    if (!hasBrands) return null

    return <>
        <div className="mb-1 max-sm:mt-4 flex flex-row items-center gap-8">
            <h4>
                {t('trademarks')}
            </h4>
            <Link to="trademarks" className="btn-secondary h-5 w-5 p-px"><IconEdit className="" /></Link>
        </div>
        <ul className="list-none">
            <li className="hidden last:block text-slate-500">{t("no-overlapping-trademarks")}</li>
            {_(families)
                .sortBy(f => f.reference)
                .map(family => <li key={family.familyId}>
                    <Link className="underline-link flex flex-row gap-1 items-center" to={trademarkFamilyUrl(family)}>
                        <ProtectionIcon {...{ isThirdParty }} /> {family.reference}
                    </Link>
                </li>)
                .value()}
        </ul>
    </>
}

export function ProductByRef() {
  const { commodityByReference } = useProducts();
  const { reference } = useParams();

  const commodity = commodityByReference[reference];

  if (!commodity) return null;

  return <Navigate to={commodityUrl(commodity)} />;
}