import { Member } from "../../patents/patents"
import { IpRight, PcIpRight, ValidationMessage } from "../../renewal/DennemeyerProvider"
import { canBeImported, canBeStopped } from "../../renewal/states"


export const renewalChangeModalStr = 'renewal-change' // TODO rename to renewalChangedStr
export type RenewalChange = 'can-be-imported' | 'has-been-stopped' | undefined

export const referenceChangedStr = 'reference-changed'

export type AfterUpdateAction = 'initiate-payment-handling' | 'stop-payment-handling' | 'move-folder' | 'update-reference'
export const afterUpdateActionsStr = 'after-update-actions'

export interface CreateChangedStateArgs { 
    oldMember?: Member, 
    newMember: Member,
    pcIpRight?: PcIpRight,
    ipRight?: IpRight,
    isIgnored: (id: number) => boolean, validationErrors?: ValidationMessage[],
    hasAnnuities: boolean, 
    hasDocuments: boolean, 
}

export type ChangedState = {
    [afterUpdateActionsStr]: AfterUpdateAction[],
    from?: Member,
    to: Member,
}

export function createChangedState({ hasAnnuities, hasDocuments, oldMember, newMember, pcIpRight, ipRight, isIgnored, validationErrors }: CreateChangedStateArgs): ChangedState | {} {

    const referenceChanged = (oldMember !== undefined) && (oldMember?.internalReference !== newMember?.internalReference)
    const afterUpdateActions = [
        hasAnnuities && 
            canBeStopped({ oldMember, newMember, pcIpRight, ipRight, isIgnored, validationErrors }) ?
                'stop-payment-handling' :
            canBeImported({ oldMember, newMember, pcIpRight, ipRight, validationErrors, isIgnored }) ?
                'initiate-payment-handling' :
                undefined,
        hasAnnuities && referenceChanged ? 'update-reference' : undefined,
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldMember,
        to: newMember,
    }
}