import { useEffect, useRef } from "react";
import {Button, Dialog, DialogTrigger, Popover} from 'react-aria-components';
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from 'clsx'
import { SideBarBadge } from "../components/badges";
import _ from 'lodash'

import { Family, Member } from "../patents/patents";
import { useDmSettings, useDocuments, useMaintenances, useTimeline } from "./DennemeyerProvider";
import ProtectionIcon from "../components/ProtectionIcon";
import { family_member, patent_family } from "../data";
import { PlainImage } from "../components/Image";
import { useRoles } from "../user/Auth";
import { commodityUrl, useProductMapping } from "../products/products";
import { scoreBackground, useFamilyScores, useMemberScores } from "../valuations/scores";
import { PatentFamilyDetails } from "../patents/Family";
import { MemberDetails } from "../patents/FamilyMember";
import { useComments } from "../comments/CommentsProvider";
import { SmallComment } from "../comments/Comments";
import { useFilteredPatents } from "../filter/FilteredPatents";
import { usePatents } from "../patents/PatentsProvider";
import { useBackend } from "../BackendProvider";
import { Disclosure, Transition } from "@headlessui/react";
import { IconPlus } from "../components/icons";

function UnfoldingSections({children}) {
    return (
        <div className="flex flex-col border-b-2 border-pcx-300 h-fit">
            {children}
        </div>
    )
}

function UnfoldingSection({title, preview = undefined, children}) {
    return (
        <Disclosure>{({open}) => <>
            <Disclosure.Button className="flex flex-row items-center gap-2 cursor-pointer text-pcx-600 border-t-2 border-pcx-300 py-1 w-full">
                <IconPlus className={clsx("shrink-0 h-5 w-5", open && "rotate-45")} />
                <h5 className="text-base text-pcx-600 shrink-0">{title}</h5>
                {preview && !open && 
                        <span className="text-gray-400 min-w-0 truncate text-sm"> - {preview}</span> }
            </Disclosure.Button>
            <Transition
                enter="duration-200 ease-out transition"
                enterFrom="opacity-0 scale-y-95"
                enterTo="opacity-100 scale-y-100"
                leave="duration-200 ease-in transition"
                leaveFrom="opacity-100 scale-y-100"
                leaveTo="opacity-0 scale-y-95"
            >
                <Disclosure.Panel className="text-sm text-gray-500 pb-2 pl-7">
                    {children}
                </Disclosure.Panel>
            </Transition>
        </>}</Disclosure>
    )
}

export function FamilyGridDetails({family}) {
    const {t} = useTranslation()

    const {tagsLookup} = useBackend()

    const {hasClaimScopes} = useRoles()
    const {membersByFamilyId} = useFilteredPatents()
    const {commentsLookUp} = useComments()
    const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()
    const commodities = _(((hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {})[family.patentFamilyId] ?? commoditiesByFamilyId[family.patentFamilyId]) ?? [])
        .filter(Boolean)
        .sortBy(c => `${c.commodityClass ?? t("unclassified")} ${c.commodityReference}`.toLocaleLowerCase())
        .value()

    const familyComments = commentsLookUp[patent_family]?.[family?.patentFamilyId] ?? []
    const memberComments = (membersByFamilyId[family.patentFamilyId] ?? []).flatMap(m => commentsLookUp[family_member]?.[m.familyMemberId] ?? [])

    const tags = tagsLookup[patent_family]?.[family.patentFamilyId] ?? []

    return <>
        <div className="h-32 p-1">
            <PlainImage {...{ entity: patent_family, entityId: family.patentFamilyId, clickable: false }} />
        </div>

        <div className="min-w-0">
            <UnfoldingSections>
                <UnfoldingSection title={t('summary')} preview={family.summary}>{family.summary ?? '-'}</UnfoldingSection>
                <UnfoldingSection title={t('products')} preview={`${commodities.length}`}>
                    <ul className="list-none pb-2">
                        <li className="hidden only:block text-slate-400">{t('none')}</li>
                        {commodities.map(c =>
                            <li key={c.commodityId}>
                                <Link className="flex flex-row items-center" to={commodityUrl(c)}>
                                    <ProtectionIcon isThirdParty={c.isThirdParty} />{c.commodityClass ?? t("unclassified")}: {c.commodityReference}
                                </Link>
                            </li>)}
                    </ul>
                </UnfoldingSection>
            </UnfoldingSections>
            <div className="flex flex-row flex-wrap gap-1 text-xs text-gray-600 py-2 empty:py-0">
                {tags.map((tag) => <div key={tag} className="py-0.5 px-2 rounded-sm shadow-sm bg-pcx-300/50">{tag}</div>)}
            </div>
        </div>

        <div className="col-span-2 text-xs max-h-52 overflow-y-auto mb-2 pr-1 text-slate-500">
            <div className="flex flex-row gap-1 justify-between">
                <h5 className="pb-1 text-slate-600 text-base max-sm:only:hidden">{t('comments')}</h5>
                <Link className="btn-tertiary px-0" to={`comment?entity=${patent_family}&entityId=${family.patentFamilyId}`}>+ {t('add-comment')}</Link>
            </div>
            {[...familyComments, ...memberComments].map(c => <SmallComment key={`comment-${c.commentId}`} to="comment" {...c} />)}
        </div>
    </>
}

export function FamilyDetails({family}) {
    const {t} = useTranslation()

    const {tagsLookup} = useBackend()

    const {hasClaimScopes} = useRoles()
    const {membersByFamilyId} = useFilteredPatents()
    const {commentsLookUp} = useComments()
    const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()
    const commodities = _(((hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {})[family.patentFamilyId] ?? commoditiesByFamilyId[family.patentFamilyId]) ?? [])
        .filter(Boolean)
        .sortBy(c => `${c.commodityClass ?? t("unclassified")} ${c.commodityReference}`.toLocaleLowerCase())
        .value()

    const familyComments = commentsLookUp[patent_family]?.[family?.patentFamilyId] ?? []
    const memberComments = (membersByFamilyId[family.patentFamilyId] ?? []).flatMap(m => commentsLookUp[family_member]?.[m.familyMemberId] ?? [])

    const tags = tagsLookup[patent_family]?.[family.patentFamilyId] ?? []

    return (
        <div className="flex flex-col md:flex-row gap-2 md:gap-4">
            <div>
                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <div className="h-32 w-44 p-1">
                        <PlainImage {...{ entity: patent_family, entityId: family.patentFamilyId, clickable: false }} />
                    </div>
                    <div className="min-w-md max-w-md">
                        <UnfoldingSections>
                            <UnfoldingSection title={t('summary')} preview={family.summary}>{family.summary ?? '-'}</UnfoldingSection>
                            <UnfoldingSection title={t('products')} preview={`${commodities.length}`}>
                                <ul className="list-none pb-2">
                                    <li className="hidden only:block text-slate-400">{t('none')}</li>
                                    {commodities.map(c =>
                                        <li key={c.commodityId}>
                                            <Link className="flex flex-row items-center" to={commodityUrl(c)}>
                                                <ProtectionIcon isThirdParty={c.isThirdParty} />{c.commodityClass ?? t("unclassified")}: {c.commodityReference}
                                            </Link>
                                        </li>)}
                                </ul>
                            </UnfoldingSection>
                        </UnfoldingSections>
                        <div className="flex flex-row flex-wrap gap-1 text-sm text-gray-600 py-2 empty:py-0">
                            {tags.map((tag) => <div key={tag} className="py-0.5 px-2 rounded-sm shadow-sm bg-pcx-300/50">{tag}</div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-auto w-96 text-xs max-h-52 overflow-y-auto mb-2 pr-1 text-slate-500">
                <div className="flex flex-row gap-1 justify-between">
                    <h5 className="pb-1 text-slate-600 text-base max-sm:only:hidden">{t('comments')}</h5>
                    <Link className="btn-tertiary px-0" to={`comment?entity=${patent_family}&entityId=${family.patentFamilyId}`}>+ {t('add-comment')}</Link>
                </div>
                {[...familyComments, ...memberComments].map(c => <SmallComment key={`comment-${c.commentId}`} to="comment" {...c} />)}
            </div>
        </div>
    )
}

export function FamilyRibbons({family}) {
    const {familyScore} = useFamilyScores(family.patentFamilyId)

    return <ScoreRibbon score={familyScore} />
}

export function MemberRibbons({member}) {
    const score = useMemberScores(member.familyMemberId)

    return <ScoreRibbon score={score} />
}

export function ScoreRibbon({score}) {
    const {t} = useTranslation()
    return score
        ? <div className={clsx(scoreBackground(score), 'px-2 py-1 w-fit rounded-sm text-xs whitespace-nowrap text-gray-900/80')}>{t('score')}: {score}</div>
        : null

}


export function LoadButton({hasNextPage, fetchNextPage}) {
    const {t} = useTranslation()
    const ref = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            console.log('Triggering call')
            if (entry.isIntersecting)
                fetchNextPage()
        })
        if (ref.current !== undefined && hasNextPage) {
            //console.log(ref.current)
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [fetchNextPage, hasNextPage, ref])

    return (
        <button 
            ref={ref}
            onClick={() => fetchNextPage()} 
            className="btn btn-primary"
        >{t('load-more')}</button>
    )
}

export function RenewalsBadge({smallMenu}) {
    const {settings} = useDmSettings()

    if (settings?.clientId && settings?.clientSecret)
        return <RenewalsBadgeWithCredentials {...{smallMenu}} />
    else
        return null
}

function RenewalsBadgeWithCredentials({smallMenu}) {
    const {documentPages: newDocuments} = useDocuments({onlyNew: true})
    const {data: openMaintenances} = useMaintenances({onlyInstructable: false, onlyOpen: true})
    const { calculateDueDates } = useTimeline()

    const has_new_documents = newDocuments?.pages?.flatMap(d => d.Data?.Page ?? [])?.length > 0
    const has_open_maintenances = openMaintenances?.pages?.flatMap(d => d.Data?.Page ?? [])?.length > 0

    const has_new = has_new_documents || has_open_maintenances

    const warn = (openMaintenances?.pages?.flatMap(d => d.Data?.Page ?? []) ?? []).find(m => {
        if (!m.PermanentOrder) {
            const {status} = calculateDueDates(m.DueDate)
            return status === 'critical' || status === 'manual-instruction'
        } else return false
    }) !== undefined
    const severity = warn ? 'warning' : has_new ? 'info' : 'no-show'
    //const has_new = true
    //const warn = true
    //console.log({has_new, newDocs: newDocuments, maint: openMaintenances})

    return <SideBarBadge {...{smallMenu, severity}} />
}


export function FamilyInfoButton({family}: {family: Family}) {
    return (
        <DialogTrigger>
            <Button><InformationCircleIcon className="h-5 text-pcx-500 hover:text-pcx-400 aspect-square" /></Button>
            <Popover>
                <Dialog className="bg-white rounded-lg shadow p-4 border border-pcx-300 max-w-3xl">
                    <PatentFamilyDetails {...{family, isEditUser: false}} />
                </Dialog>
            </Popover>
        </DialogTrigger>
    )
}

export function MemberInfoButton({member}: {member: Member}) {
    const {familyById} = usePatents()
    const family = familyById[member.patentFamilyId]

    return (family && 
        <DialogTrigger>
            <Button><InformationCircleIcon className="h-5 text-pcx-400 hover:text-pcx-300 aspect-square" /></Button>
            <Popover>
                <Dialog className="bg-white rounded-lg shadow p-4 border border-pcx-300 max-w-3xl">
                    <MemberDetails {...{family, member, isEditUser: false}} />
                </Dialog>
            </Popover>
        </DialogTrigger>
    )
}