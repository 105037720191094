import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { Link, Outlet } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { getLocalTimeZone, today as createToday } from '@internationalized/date';
import { ArrowRightOnRectangleIcon, FunnelIcon, RocketLaunchIcon } from "@heroicons/react/24/solid"
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, XMarkIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"

import { useHints } from "./Hints"
import { MaintenanceAction, useMaintenances, useTimeline } from "./DennemeyerProvider"
import { SortButton } from "../patents/datawizard/DataWizard"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { AugmentedMaintenanceAction, calculateHardInstructionCuttoff, createClaimScopeGroups, isUndecided, useAugmentMaintenanceAction } from "./utils"
import { Family, Member } from "../patents/patents"
import { IconChevronDown, IconChevronUp, IconSpinner } from "../components/icons"
import { familyUrl, memberUrl } from "../patents/utils"
import { FeeAmount, StatusView } from "./MaintenanceActionInstruction"
import { DueDateStatus } from "./settings/instruction_timeline"
import { useLocalState } from "../settings/localStorage"
import { Popover } from "@headlessui/react"
import { useRoles } from "../user/Auth"
import DateRangePicker from "../components/input/DateRangePicker";
import { useClaimScopes } from "../products/products";
import { PlainImage } from "../components/Image";
import { noHtml } from "../utils/strings";
import { FamilyGridDetails, MemberRibbons } from "./components";

export default function SimpleMaintenanceActions() {
    const {t} = useTranslation()

    const {hint, isHintNeeded} = useHints()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('open-maintenances')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <MaintenanceFilterProvider>
            <div className="header-row pb-1 flex flex-row gap-1 items-center max-w-6xl xl:max-w-7xl pr-2">
                <h2 className="modern-h2 mr-auto">{t('open-maintenances')}</h2>
                <MaintenanceFilterTags />
                <MaintenanceFilterMenu />
            </div>
            <div className="px-2 pb-6 max-w-6xl xl:max-w-7xl">
                {isHintNeeded ? hint : <MaintenanceActionsTable />}
            </div>
        </MaintenanceFilterProvider>
    </>
}

const sortByValues = [
    'internalReference',
    'due',
]

// Keep value at 0 at 'no grouping'/'by family member'
const groupByValues = [
    "by-family-member",
    "by-patent-family",
    "by-claim-scopes",
]

const today = createToday(getLocalTimeZone())

const MaintenanceFilterContext = React.createContext({
    onlyUndecided: true as boolean,
    setOnlyUndecided: (() => {}) as Dispatch<SetStateAction<boolean>>,
    groupBy: groupByValues[0], 
    setGroupBy: (() => {}) as Dispatch<SetStateAction<string>>,
    sortBy: sortByValues[0], 
    setSortBy: (() => {}) as Dispatch<SetStateAction<string>>,
    sortOrder: 1 as number, 
    setSortOrder: (() => {}) as Dispatch<SetStateAction<number>>,
    customRange: false as boolean,
    setCustomRange: (() => {}) as Dispatch<SetStateAction<boolean>>,
    minDate: undefined as string,
    setMinDate: (() => {}) as Dispatch<SetStateAction<string>>,
    maxDate: undefined as string,
    setMaxDate: (() => {}) as Dispatch<SetStateAction<string>>,
    smallView: false as boolean,
    setSmallView: (() => {}) as Dispatch<SetStateAction<boolean>>,
    showDetailsFor: (patentFamilyId: number) => true as boolean,
    setShowDetailsFor: (patentFamilyId: number, value: boolean) => {},
})

function MaintenanceFilterProvider({children}) {
    const [groupBy, setGroupBy] = useLocalState('maintenances-group-by', groupByValues[0])
    const [onlyUndecided, setOnlyUndecided] = useLocalState('maintenances-only-undecided', false)

    const [customRange, setCustomRange] = useLocalState('maintenances-only-custom-range', false)
    const [minDate, setMinDate] = useLocalState('maintenances-min-date', today.toString())
    const [maxDate, setMaxDate] = useLocalState('maintenances-max-date', today.add({months: 6}).toString())

    const [sortBy, setSortBy] = useLocalState('maintenances-sorty-by', sortByValues[0])
    const [sortOrder, setSortOrder] = useLocalState('maintenances-sorty-order', -1)

    const [smallView, setSmallView] = useLocalState('maintenances-small-view', false)
    const [showDetails, setShowDetails] = useState({}) // undefined is like true for all; TODO local state

    useEffect(() => {
        setShowDetails(ds => {})
    }, [smallView])

    function showDetailsFor(patentFamilyId: number) {
        if (showDetails && patentFamilyId in showDetails) return showDetails[patentFamilyId]
        else return !smallView
    }

    function setShowDetailsFor(patentFamilyId: number, value: boolean) {
        setShowDetails(d => ({...d, [patentFamilyId]: value}))
    }

    const value = {
        onlyUndecided, setOnlyUndecided,
        groupBy, setGroupBy,
        sortBy, setSortBy,
        sortOrder, setSortOrder,
        customRange, setCustomRange,
        minDate, setMinDate,
        maxDate, setMaxDate, 
        smallView, setSmallView,
        showDetailsFor, setShowDetailsFor,
    }

    return <MaintenanceFilterContext.Provider value={value}>
        {children}
    </MaintenanceFilterContext.Provider>
}

function useMaintenanceFilter() {
    return React.useContext(MaintenanceFilterContext)
}

function MaintenanceFilterTags() {
    const {t} = useTranslation()

    const { groupBy, setGroupBy, onlyUndecided, setOnlyUndecided,
        customRange, setCustomRange, minDate, maxDate, 
     } = useMaintenanceFilter()

    const tags = [
        groupBy !== groupByValues[0] &&
        {label: t(groupBy), close: () => setGroupBy(groupByValues[0])},
        !onlyUndecided &&
        {label: 'Include Decided Cases', close: () => setOnlyUndecided(true)},
        customRange && 
        {label: `${minDate} - ${maxDate}`, close: () => setCustomRange(false)},
    ].filter(Boolean)

    return <>{tags.map(({ label, close }) =>
        <div key={label} className="px-1.5 py-1 h-fit bg-pcx-500 hover:bg-pcx-400 text-white text-xs rounded-sm flex flex-row items-center">
            {label}
            <button onClick={close}><XMarkIcon className="h-4 w-4 ml-1" /></button>
        </div>
    )} </>
}

// TODO: Filter date must be for the instruction date and not the fee due date
function MaintenanceFilterMenu() {
    const {t} = useTranslation()
    const {hasClaimScopes} = useRoles()
    const finalGroupByValues = groupByValues.filter(v => v !== 'by-claim-scopes' || hasClaimScopes)

    const {
        onlyUndecided, setOnlyUndecided, groupBy, setGroupBy, 
        customRange, setCustomRange, minDate, setMinDate, maxDate, setMaxDate,
    } = useMaintenanceFilter()

    return (
        <Popover className="relative">
            <Popover.Button className="text-pcx-500 hover:text-pcx-500 p-1">
                <FunnelIcon className="w-4 h-4 " />
            </Popover.Button>
            <Popover.Panel className="absolute mt-1 right-0 z-10 bg-white shadow-lg p-4 rounded-xl border-2 border-pcx-200">
                <div className="grid grid-cols-[auto_auto_auto] gap-x-4 gap-y-1 items-center text-sm">
                    <div className="label">{t('include-decided-cases')}</div>
                    <input type="checkbox" className="form-checkbox" checked={!onlyUndecided} onChange={() => setOnlyUndecided(b => !b)} />
                    <div />

                    <div className="label">{t('grouping')}</div>
                    <div />
                    <select className="form-select text-sm py-1 px-2" value={groupBy} onChange={e => setGroupBy(e.target.value)}>
                        {finalGroupByValues.map(v => <option key={v} value={v}>{t(v)}</option>)}
                    </select>

                    <div className="label normal-case">{t('instruction-due-within')}</div>
                    <input type="checkbox" className="form-checkbox" checked={customRange} onChange={() => setCustomRange(b => !b)} />
                    <DateRangePicker
                        name="date range maintenances"
                        minValue={today}
                        className={clsx("text-sm py-1 px-2 bg-transparent w-fit", !customRange && "opacity-50")}
                        title={t('invoice-due-within')}
                        disabled={!customRange}
                        {...{ minDate, maxDate, setMinDate, setMaxDate }} />
                </div>
            </Popover.Panel>
        </Popover>
    )
}

const hardInstructionCuttoff = calculateHardInstructionCuttoff()

// Entweder open & undecided oder custom range und dann alle
function MaintenanceActionsTable() {
    const {t} = useTranslation()
    const {firstPossibleFeeDueDate, latestPossibleFeeDueDate} = useTimeline()

    const {
        onlyUndecided, groupBy,
        sortBy, setSortBy,
        sortOrder, setSortOrder,
        customRange, minDate, maxDate,
    } = useMaintenanceFilter()

    const {data, fetchNextPage, hasNextPage, isLoading} = useMaintenances({
        onlyOpen: true, onlyInstructable: false,
        ...(customRange ? {minDate: firstPossibleFeeDueDate(minDate), maxDate: latestPossibleFeeDueDate(maxDate)} : {}),
    })
    // Load the rest of the maintenance actions
    useEffect(() => {
        if (hasNextPage && !isLoading)
            fetchNextPage()
    }, [fetchNextPage, hasNextPage, isLoading])

    const {augmentMaintenanceAction} = useAugmentMaintenanceAction(!onlyUndecided)
    const {familyById} = useFilteredPatents(!onlyUndecided)
    const {claimScopeIdsByMemberId} = useClaimScopes()

    const sortByFunction = sortBy === 'internalReference' 
        ? (m: MaintenanceAction & {member: Member}) => m.member.internalReference 
        : (m: MaintenanceAction) => m.DueDate

    const groupFunction 
        = groupBy === 'by-patent-family' || groupBy === 'by-claim-scopes' 
        ? (m: {member: Member}) => m.member.patentFamilyId
        : (m: {member: Member}) => '' 

    const sortGroup: (arg : [key: string, values: AugmentedMaintenanceAction[]]) => string = 
        groupBy === 'by-patent-family'
            ? (sortBy === 'internalReference'
                ? ([key, values]) => familyById[key]?.internalReference ?? key
                : ([key, values]) => _(values).map('DueDate').min()) // or max ?
        : groupBy === 'by-claim-scopes'
            ? (sortBy === 'internalReference'
                ? ([key, values]) => familyById[values[0]?.member?.familyMemberId]?.internalReference ?? key
                : ([key, values]) => _(values).map('DueDate').min()) // or max ?
            : ([key, values]) => key

    const filterRow = useMemo(() => {
        const today = new Date().toISOString().slice(0, 10)
        const filters = [
            (m: AugmentedMaintenanceAction) => (m.member !== undefined && m.member.patentFamilyId !== undefined && m.DueDate >= today),
            onlyUndecided && ((m: AugmentedMaintenanceAction) => isUndecided(m, m.instruction, hardInstructionCuttoff)),
            customRange && ((m: AugmentedMaintenanceAction) => minDate <= m.instructionDueDate && m.instructionDueDate <= maxDate),
        ].filter(Boolean)
        return (m: AugmentedMaintenanceAction) => filters.every(f => f(m))
    }, [onlyUndecided, customRange, minDate, maxDate])

    const headers = [
        {key: 'patent', label: t('patent'),
            button: <SortButton {...{searchField: 'internalReference', sortField: sortBy, sortOrder, setSortField: setSortBy, setSortOrder}}/>},
        {key: 'due', label: t('due'),
            button: <SortButton {...{searchField: 'due', sortField: sortBy, sortOrder, setSortField: setSortBy, setSortOrder}}/>},
        {key: 'fees', label: t('fees') },
        { key: 'instruction', label: '', className: 'text-right', button: groupBy !== 'by-family-member' && <ToggleDetailsView /> },
    ]

    const maintenanceActions = _(data?.pages?.flatMap(d => d.Data?.Page ?? []) ?? [])
        .filter(Boolean)
        .map(augmentMaintenanceAction)
        .filter(filterRow)
        .groupBy(groupFunction)
        .mapValues(vs => _(vs)
            .sortBy(sortByFunction)
            .tap(ms => sortOrder > 0 ? ms : _.reverse(ms))
            .value())
        .toPairs()
        .sortBy(sortGroup)
        .tap(ms => sortOrder > 0 ? ms : _.reverse(ms))
        .value()

    //console.log({maintenanceActions})
    return <>
        <div className="grid gric-cols-[auto_fr_auto_auto] gap-x-6 gap-y-4">
            {/* Header */}
            <div className="grid grid-cols-subgrid col-span-4 border-b-2 border-pcx-300">
                {headers.map((h, i) =>
                    h.key 
                        ?  <div key={h.key ?? i} className={clsx(
                        "py-2 xpx-3 text-sm text-pcx-600 font-semibold uppercase tracking-wider",
                        "first:pl-4 last:pr-4",
                        h.className && h.className,
                    )}>{h.label} {h.button}</div>
                    : <div key={i} />
                )}
            </div>
            <div className="hidden last:flex flex-row gap-4 items-center p-4 bg-white rounded-xl shadow-lg border border-pcx-200 col-span-4">
                {isLoading
                    ? <div className="w-12 h-12 p-2 text-pcx-400">
                        <IconSpinner className="w-8 h-8 animate-spin" />
                    </div>
                    : <div className="w-12 h-12 p-2 rounded-xl bg-pcx-400 text-white">
                        <RocketLaunchIcon className="w-8 h-8" />
                    </div>}
                <div className="text-2xl text-pcx-500">
                    {t('no-open-instructions')} 
                    <Link to="/renewals/history" className="text-slate-600 ml-4 text-lg">
                        <ArrowRightOnRectangleIcon className="inline mr-1.5 h-6 w-6 mb-0.5" />
                        <span className="underline">{t('past-maintenances')}</span>
                    </Link>
                </div>
            </div>

            {
                (groupBy === 'by-family-member' && maintenanceActions?.length > 0)
                ? <TableCard>
                        {maintenanceActions?.flatMap(([key, actions], i) =>
                            actions.map(maintenanceAction =>
                                <MaintenanceActionsRow key={maintenanceAction.DennemeyerId} {...{ maintenanceAction }} />)
                        )}
                    </TableCard>
                : groupBy === 'by-patent-family'
                ? maintenanceActions?.map(([key, actions], i) => {
                    const family = familyById[actions[0]?.member?.patentFamilyId]

                    if (!family) return null
                    return (
                        <TableCard key={key}>
                            <FamilyHeader {...{ family }} />
                            {actions.map(maintenanceAction =>
                                <MaintenanceActionsRow key={maintenanceAction.DennemeyerId} {...{ maintenanceAction }} />)
                            }
                            <FamilyFooter {...{ family, inDetail: actions.map(a => a.member?.familyMemberId).filter(Boolean) }} />
                        </TableCard>
                    )
                })
                : maintenanceActions?.flatMap(([key, actions], i) => {
                    const family = familyById[actions[0]?.member?.patentFamilyId]
                    if (!family) return null

                    const { onlyFamily, claimScopes } = createClaimScopeGroups(actions, claimScopeIdsByMemberId)
                    
                    return [
                        <div key={family.patentFamilyId} className={clsx("col-span-4 grid grid-cols-subgrid pl-2 max-w-full", i > 0 && "mt-10")}>
                            <FamilyHeader key={key} {...{family}} />
                            <FamilyFooter {...{family, inDetail: actions.map(a => a.member?.familyMemberId).filter(Boolean)}} />
                        </div>,
                        onlyFamily.length > 0 ? <TableCard>
                            {onlyFamily.map(maintenanceAction =>
                                <MaintenanceActionsRow key={maintenanceAction.DennemeyerId} {...{ maintenanceAction }} />)
                            }
                        </TableCard> : null,
                        ..._(claimScopes).toPairs()
                            .map(([k, { claimScopeIds, members }], borderIndex) => {
                                return (
                                    <TableCard key={family.patentFamilyId + '-' + borderIndex} indent>
                                        <ClaimScopeHeader key={k} {...{ claimScopeIds, family, claimScopeGroupIndex: borderIndex }} />
                                        {members.map(maintenanceAction =>
                                            <MaintenanceActionsRow indent key={maintenanceAction.DennemeyerId} {...{ maintenanceAction }} />)}
                                    </TableCard>
                                )
                        }).value()
                    ]
                })
            }

        </div>
        <Outlet />
    </>
}

function ToggleDetailsView() {
    const {smallView, setSmallView} = useMaintenanceFilter()

    return (
        <button onClick={() => setSmallView(!smallView)}>{
            smallView 
                ? <ArrowsPointingOutIcon className="w-3 h-3 mt-1" />
                : <ArrowsPointingInIcon className="w-3 h-3 mt-1" />
        }</button>
    )
}


function InstructionLink({status, maintenanceAction}: {status: DueDateStatus, maintenanceAction?: MaintenanceAction}) {
    const {t} = useTranslation()
    if (maintenanceAction === undefined || status === 'too-late' || maintenanceAction.DennemeyerId === undefined) {
        return null
    } else {
        return ( 
            <Link className="btn-secondary xblock text-base w-fit h-fit" to={`instruct/${maintenanceAction.DennemeyerId}`}>
                {maintenanceAction?.PermanentOrder ? t('edit') : t('instruct')}
            </Link>
        )
    }
}

function TableCard({children, indent = false}) {
    return (
        <div className={clsx(
            "grid grid-cols-subgrid col-span-4 gap-x-6 py-3 px-2 bg-white rounded-xl shadow-lg border border-pcx-200",
            indent && "ml-6",
        )}>
            {children}
        </div>
    )
}

function FamilyHeader({family}: {family: Family}) {
    const {showDetailsFor, setShowDetailsFor} = useMaintenanceFilter()
    const showDetails = showDetailsFor(family.patentFamilyId)

    return <>
        <h3 className="col-span-4 px-2 pb-2 max-w-full truncate flex flex-row justify-between gap-2">
            <Link className="min-w-0 underline-link text-ellipsis overflow-hidden" to={familyUrl(family)}>{family.internalReference}: {family.familyName}</Link>
            <button className="shrink-0 text-pcx-500" onClick={() => setShowDetailsFor(family.patentFamilyId, !showDetails)} >{showDetails
                ? <IconChevronUp />
                : <IconChevronDown />
            }</button>
        </h3>
        {showDetails && <div className="px-2 col-span-4 grid grid-cols-subgrid pt-1">
            <FamilyGridDetails family={family} />
        </div>}
    </>
}

const countryStyle = "after:content-[','] mr-1 last:mr-0 last:after:content-none text-pcx-700"

function FamilyFooter({family, inDetail}: {family: Family, inDetail: number[]}) {
    const {t} = useTranslation()

    const {showDetailsFor} = useMaintenanceFilter()
    const showDetails = showDetailsFor(family.patentFamilyId)
    const {membersByFamilyId} = useFilteredPatents()
    const missing = (membersByFamilyId[family.patentFamilyId] ?? []).filter(m => !inDetail.includes(m.familyMemberId))

    if (missing.length === 0 || !showDetails) return null

    const byCountry = _(missing).groupBy(m => m.countryCode).toPairs().sortBy(0).value()

    return <>
        <div className="col-span-4 pt-2 mt-1.5 px-2 first:pt-0 first:mt-0 border-t-2 first:border-t-0 border-pcx-200 text-slate-600" >
            {t('other-countries')}: {byCountry.map(([country, members]) =>
                members.length === 1 
                    ? <span key={country} className={countryStyle}><Link to={memberUrl(members[0])} className="underline-link">{country}</Link></span>
                    : <Popover key={country} className={clsx(countryStyle, "relative inline")}>
                        <Popover.Button className="underline-link inline px-0">{country}</Popover.Button>
                        <Popover.Panel className="absolute mt-1 left-0 z-10 bg-white shadow-lg p-4 space-y-1 rounded-xl border-2 border-pcx-200">
                            {members.map(member => <Link key={member.familyMemberId} to={memberUrl(member)} className="block whitespace-nowrap underline-link">{member.internalReference}</Link>)}
                        </Popover.Panel>
                    </Popover>
            )}
        </div>
    </>
}

// TODO: Claim scopes schöner
function ClaimScopeHeader({claimScopeIds, family, claimScopeGroupIndex = undefined}: {claimScopeIds: number[], family: Family, claimScopeGroupIndex?: number}) {
    const {t} = useTranslation()
    const {claimscopeById, membersByClaimScopeId} = useClaimScopes()

    const {smallView} = useMaintenanceFilter()
    const [showDetails, setShowDetails] = useState(!smallView)
    useEffect(() => setShowDetails(!smallView), [smallView])

    if (claimScopeIds === undefined || claimScopeIds.length === 0) 
        return null

    return (
        <div className="col-span-4 px-2">
            <h4 className="text-pcx-600 hover:text-pcx-500 flex flex-row justify-between gap-2">
                <Link to={familyUrl(family)}>{family.internalReference} - {t('claimScope')} {claimScopeGroupIndex + 1}</Link>
                <button className="shrink-0 text-pcx-500" onClick={() => setShowDetails(d => !d)} >{showDetails
                    ? <IconChevronUp />
                    : <IconChevronDown />
                }</button>
            </h4>

            {showDetails && <div className="flex flex-row gap-4 max-w-[80rem] overflow-x-auto">
                {claimScopeIds.map(id =>  {
                    const claimScope = claimscopeById[id]
                    const members = membersByClaimScopeId[id] ?? []
                    return claimScope && members.length > 0 && (
                        <div key={id} className="flex flex-row gap-2 md:gap-4 items-start" title={noHtml(claimScope.claimScopeSummary)}>
                            <div className="w-44 h-32 p-1">
                                <PlainImage entity={"claim-scope"} entityId={id} title={undefined} text={undefined} />
                            </div>
                            <div className="w-80 text-slate-500 text-sm pt-1">
                                <h5 className="pb-1 text-slate-600 text-base">{t('claim-noun')} {members[0]?.claim?.claimNumber ?? ''}</h5>
                                <div className="text-xs" dangerouslySetInnerHTML={{ __html: claimScope.claimScopeSummary }}></div>
                            </div>
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}

// TODO: Rows enger
function MaintenanceActionsRow({maintenanceAction, indent = false}: {indent?: boolean, maintenanceAction: AugmentedMaintenanceAction}) {
    const {t} = useTranslation()

    const {member, instruction, status, instructionDueDate, fees} = maintenanceAction

    return (
        <div className="col-span-4 grid grid-cols-subgrid gap-x-6 px-2 pt-1.5 mt-1.5 first:pt-0 first:mt-0 border-t-2 first:border-t-0 border-pcx-200">
            <div>
                <Link to={memberUrl(member)} className="underline-link text-lg">
                    {member.internalReference}
                </Link>
                <div className="flex flex-row gap-1">
                    <ExpiryRibbon {...{member}} />
                    <MemberRibbons {...{member}} />
                </div>
            </div>
            <div>
                <div className="flex flex-row gap-2 items-baseline">
                    <InstructionDueDate {...{status, instructionDueDate, maintenanceAction}} />
                </div>
                <div className="-ml-2">
                    <StatusView {...{status, instruction, maintenanceAction}} />
                </div>
            </div>
            <div className="flex flex-col items-end w-32" title={`${maintenanceAction.DueDate} ${t('renewal-fee-due')}`}>
                {_(fees)
                    .toPairs()
                    .map(([currency, amount]) =>
                        <FeeAmount key={currency} {...{ amount, currency }} />)
                    .value()}
                {maintenanceAction.Annuity &&
                    <div className="whitespace-nowrap text-sm text-slate-600">{t('annuity')}: {maintenanceAction.Annuity}</div>}
            </div>
            <div className="text-right self-center xpt-1.5">
                <InstructionLink {...{status, maintenanceAction}} />
            </div>
        </div>
    )
}
export function ExpiryRibbon({member}: {member: Member}) {
    const {t} = useTranslation()
    return member.expiryDate 
        ? <div className="text-xs ribbon rounded-sm w-fit"><span className="text-slate-500">{t('expires')}:</span> {member.expiryDate}</div> 
        : null
}

export function InstructionDueDate({instructionDueDate, status, maintenanceAction}: {instructionDueDate: string, status: DueDateStatus, maintenanceAction?: MaintenanceAction}) {
    const {t} = useTranslation()

    if (instructionDueDate === undefined) return null

    const isDueDateClose = status === 'critical' && !maintenanceAction.PermanentOrder
    const isPastDueDate = (status === 'manual-instruction' || status === 'too-late') && !maintenanceAction.PermanentOrder

    //console.log({status, isDueDateClose, isPastDueDate, isNeededToContactDm})

    const statusBubble = <div className={clsx(
        isPastDueDate
        ? "bg-warn-400/20 text-warn-400" 
        : isDueDateClose ? "bg-yellow-400/20 text-yellow-400"
        : "bg-pcx-400/20 text-pcx-400",
        "p-0 rounded-full block")}
    >
        <div className="w-2 h-2 bg-current rounded-full" />
    </div>

    return (
        <>
            <div className="my-auto">{statusBubble}</div>
            <div
                title={t('due')}
                className={clsx(
                    "tabular-nums font-semibold md:text-lg inline-flex items-center gap-2",
                    isPastDueDate ? "text-warn-700" :
                        isDueDateClose ? "text-yellow-700" :
                            "text-pcx-900",
                )}>
                {instructionDueDate}
            </div>
            <div className={
                clsx(
                    isPastDueDate ? "text-warn-900" : "text-slate-600",
                    "text-sm align-middle whitespace-nowrap")}>
                {isPastDueDate
                    ? t('past-instruction-due-date')
                    : t('instruction-due')}
            </div>
        </>
    )
}
