import { getLocalTimeZone, today as createToday } from '@internationalized/date';
import _ from "lodash";

import { Member } from "../patents/patents";
import { nonEmptyString, nonEmptyDate, firstUpperCase } from "../utils/strings";
import { IpRight, PcIpRight, ValidationMessage, FeeActual, FeeEstimated, MaintenanceAction, PcInstruction, useTimeline, useDennemeyer, DmStatus, MaintenanceActionPhase } from "./DennemeyerProvider";
import { DueDateStatus } from './settings/instruction_timeline';
import { useFilteredPatents } from '../filter/FilteredPatents';

export function extractDiscrepancies(member: Member, ipRight: IpRight): Discrepancy[] {
    return _([
        { check: isNotEquivalentStatus, extract: extractStatusDiscrepancy },
        { check: isNotEquivalentApplicationNumber, extract: extractApplicationDiscrepancy },
        { check: isNotEquivalentPublicationNumber, extract: extractPublicationDiscrepancy },
        { check: isNotEquivalentGrantNumber, extract: extractGrantDiscrepancy },
        { check: isNotEquivalentClaims, extract: extractClaimsDiscrepancy },
        { check: isNotEquivalentCountryCode, extract: extractCountryCodeDiscrepancy },
    ])
        .map(({ check, extract }) => check({ member, ipRight }) ? extract({ member, ipRight }) : undefined)
        .filter(d => d !== undefined)
        .value()
}

export function extractDiscrepancy(member: Member, ipRight: IpRight): Discrepancy | undefined {
    const discrepancy = _.cond([
        [isNotEquivalentStatus, extractStatusDiscrepancy],
        [isNotEquivalentApplicationNumber, extractApplicationDiscrepancy],
        [isNotEquivalentPublicationNumber, extractPublicationDiscrepancy],
        [isNotEquivalentGrantNumber, extractGrantDiscrepancy],
        [isNotEquivalentClaims, extractClaimsDiscrepancy],
        [isNotEquivalentCountryCode, extractCountryCodeDiscrepancy],
    ])({member, ipRight})

    //console.log({discrepancy})
    return discrepancy
}

export type Discrepancy = { pc: string, dm: string, key: string }

type EquivalenceCheckProps = {member: Member, ipRight: IpRight } //, pcIpRight: PcIpRight}

function isNotEquivalentStatus({member, ipRight} : EquivalenceCheckProps) {
    //console.log({member, ipRight})
    //console.log({
    //        pending: (member.familyMemberStatus === "pending" && ipRight.Status.includes("Pending")),
    //        granted: (member.familyMemberStatus === "granted" && ipRight.Status.includes("Granted")),
    //        stopped: (member.familyMemberStatus === "stopped" && ipRight.Status.includes("Inactive"))
    //})

    return !((member.familyMemberStatus === "pending" && ipRight.Status.includes("Pending")) ||
        (member.familyMemberStatus === "granted" && ipRight.Status.includes("Granted")) ||
        (member.familyMemberStatus === "stopped" && ipRight.Status.includes("Inactive")))
}

function extractStatusDiscrepancy({member, ipRight}: EquivalenceCheckProps) {
    return {
        pc: member.familyMemberStatus,
        dm: ipRight.Status,
        key: 'familyMemberStatus',
    }
}

function prepare(num: string) {
    if (!num) return undefined
    // Remove any kind code from the start
    let noNumericKindCode = num
    if (num.startsWith('10-')) {
        noNumericKindCode = num.substring(3)
    }
    const noAlphabeticalKindCode = noNumericKindCode.replace(/[A-Za-z]{1}$/, '')

    const trimmed = noAlphabeticalKindCode.trim().replaceAll(/[ -._]/g, '')
    const noCountry = trimmed.replace(/^[A-Za-z]{2}/, '')
    //console.log({noCountry})
    return noCountry
}

export function areEquivalentNumbers(num1: string, num2: string) {
    //console.log(prepare(num1) === prepare(num2))
    return prepare(num1) === prepare(num2)
}

function isNotEquivalentApplicationNumber({member, ipRight} : EquivalenceCheckProps) {
    return !(areEquivalentNumbers(member.applicationNumber, ipRight?.Application?.Number) && 
        member.applicationDate === ipRight?.Application?.Date)
}

function isNotEquivalentPublicationNumber({member, ipRight} : EquivalenceCheckProps) {
    return !(areEquivalentNumbers(member.publicationNumber, ipRight?.Publication?.Number) && 
        member.publicationDate === ipRight?.Publication?.Date)
}

function isNotEquivalentGrantNumber({member, ipRight} : EquivalenceCheckProps) {
    return !(areEquivalentNumbers(member.patentNumber, ipRight?.Grant?.Number) && 
        member.patentDate === ipRight?.Grant?.Date)
}

function numberAsString(number?: string, date?: string) {
    if (number && date) 
        return `${number} (${date})`
    else if (number)
        return number
    else
        return ""
}

function extractApplicationDiscrepancy({member, ipRight}) {
    return {
        pc: numberAsString(member.applicationNumber, member.applicationDate),
        dm: numberAsString(ipRight?.Application?.Number, ipRight?.Application?.Date),
        key: 'applicationNumber',
    }
}

function extractPublicationDiscrepancy({member, ipRight}) {
    return {
        pc: numberAsString(member.publicationNumber, member.publicationDate),
        dm: numberAsString(ipRight?.Publication?.Number, ipRight?.Publication?.Date),
        key: 'publicationNumber',
    }
}

function extractGrantDiscrepancy({member, ipRight}) {
    return {
        pc: numberAsString(member.patentNumber, member.patentDate),
        dm: numberAsString(ipRight?.Grant?.Number, ipRight?.Grant?.Date),
        key: 'patentNumber',
    }
}

function isNotEquivalentClaims({member, ipRight} : EquivalenceCheckProps) {
    return member.numberClaims !== ipRight.NumberOfClaims
}

function extractClaimsDiscrepancy({member, ipRight}) {
    return {
        pc: member.numberClaims?.toString() || "",
        dm: ipRight.NumberOfClaims?.toString() || "",
        key: 'numberClaims',
    }
}

function isNotEquivalentCountryCode({member, ipRight} : EquivalenceCheckProps) {
    return member.countryCode !== ipRight.CountryCode
}

function extractCountryCodeDiscrepancy({member, ipRight}) {
    return {
        pc: member.countryCode,
        dm: ipRight.CountryCode,
        key: 'countryCode',
    }
}

export function isReady(member: Member) {
    return (
        nonEmptyDate(member.applicationDate) && nonEmptyString(member.applicationNumber) &&
        nonEmptyDate(member.publicationDate) && nonEmptyString(member.publicationNumber) &&
        ((nonEmptyDate(member.patentDate) && nonEmptyString(member.patentNumber) && member.familyMemberStatus === 'granted') || member.familyMemberStatus === 'pending')
    )
}

export function extractMissingValue(member: Member) {
    const isGranted = member.familyMemberStatus === 'granted'
    const isPending = member.familyMemberStatus === 'pending'
    const hasGrantDate = nonEmptyDate(member.patentDate)
    const hasGrantNumber = nonEmptyString(member.patentNumber)

    // errors can be translated with useTranslation
    return [
        (!isGranted && hasGrantDate && hasGrantNumber) && 'status-not-granted',
        (!isGranted && !isPending) && 'status-not-pending',
        !nonEmptyDate(member.applicationDate) && 'applicationDate',
        !nonEmptyString(member.applicationNumber) && 'applicationNumber',
        !nonEmptyDate(member.publicationDate) && 'publicationDate',
        !nonEmptyString(member.publicationNumber) && 'publicationNumber',
        (isGranted && !hasGrantDate) && 'patentDate',
        (isGranted && !hasGrantNumber) && 'patentNumber',
    ].filter(Boolean)
}


export type RenewalState 
    = "not-yet-ready" 
    | "ready-at-payment-provider"
    | "sent" 
    | "ignored"
    | "validation-errors"
    | "not-sent"
    | 'pct-not-importable'
    | 'ep-not-importable'
    | 'paid-for-life'

export const renewalStateOrder = {
        'pct-not-importable': -1,
        'ep-not-importable': -2,
        "ignored": 0,
        "not-yet-ready": 1,
        "validation-errors": 2,
        "not-sent": 3,
        "sent": 4,
        "ready-at-payment-provider": 5,
        "paid-for-life": 5,
}

// Get back the state from/for "renewals-desc.${state}"
// TODO: should we calculate errors here instead?
export function extractState(member: Member, pcIpRight?: PcIpRight, ipRight?: IpRight, validationErros?: ValidationMessage[]): RenewalState {
    if (member.countryCode === 'WO')
        return 'pct-not-importable'
    //else if (member.countryCode === 'EP' && !member.unitaryPatent && member.familyMemberStatus === 'granted')
    //    return 'ep-not-importable'
    else if (pcIpRight?.status === 'Inactive') 
        return 'ignored'
    else if (ipRight?.NextMaintenanceActionDueDate !== undefined && ipRight?.NextMaintenanceActionDueDate === ipRight?.ExpiryDate)
        return "paid-for-life"
    else if (ipRight?.IntegrationStatus === 'Imported' && (ipRight?.Status.includes("Granted") || ipRight?.Status.includes("Pending")))
        return "ready-at-payment-provider"
    else if (pcIpRight?.dennemeyerId !== undefined && pcIpRight?.dirty === false)
        return "sent"
    else if (member.familyMemberStatus === "in-preparation" || member.familyMemberStatus === "stopped")
        return "ignored" // TODO: adjust when 'ignored' flag is added
    else if (validationErros?.length > 0)
        return "validation-errors"
    else
        return "not-sent"
}

// IGNORING Discrepancy as it could stem from old data at DM (it takes a while until new data is available)
//// Get back the state from/for "renewals-desc.${state}"
//export function extractState(member: Member, pcIpRight?: PcIpRight, ipRight?: IpRight, validationErros?: ValidationMessage[]): [string, Discrepancy] {
//    const discrepancy = ipRight && extractDiscrepancy(member, ipRight)
//    if (discrepancy)
//        return ["has-discrepancy", discrepancy]
//    else 
//    if (ipRight?.Status.includes("Granted") || ipRight?.Status.includes("Pending"))
//        return ["ready-at-payment-provider", undefined]
//    else if (pcIpRight?.dennemeyerId !== undefined && pcIpRight?.dirty === false)
//        return ["sent", undefined]
//    else if (member.familyMemberStatus === "in-preparation" || member.familyMemberStatus === "stopped")
//        return ["ignored", undefined] // TODO: adjust when 'ignored' flag is added
//    else if (validationErros?.length > 0)
//        return ["validation-errors", undefined]
//    else
//        return ["not-sent", undefined]
//}

export function deriveStatus(
    {applicationNumber, applicationDate, patentNumber, patentDate}:
    {applicationNumber?: string, applicationDate?: string, patentNumber?: string, patentDate?: string}): {status: DmStatus} {

    const status = 
        patentNumber !== undefined && patentDate !== undefined 
            ? 'Granted' 
        : applicationNumber !== undefined && applicationDate !== undefined
            ? 'Pending'
            : 'Inactive'
    return {status}
}

export function deriveInitialIpRight({familyMemberId, ipType, patentNumber, patentDate, applicationDate, applicationNumber, pctRouteFiling, validated}: Member): PcIpRight {
    const origin = validated ? 'European' : (pctRouteFiling ? 'PCT' : 'National')
    const ipSubType = ipType === 'patent' ? 'patent' : 'utility model'
    const {status} = deriveStatus({applicationNumber, applicationDate, patentNumber, patentDate})
    return {
        familyMemberId,
        ipType,
        ipSubType,
        origin,
        status,
        dirty: true,
    }
}

function emptyListAsUndefined<T>(list?: T[]): T[] | undefined {
    if (list?.length === 0) return undefined
    else return list
}

export function calcFees({FeesActual, FeesEstimated}: {FeesActual?: FeeActual[], FeesEstimated?: FeeEstimated[]}) {
    const fees = _(emptyListAsUndefined(FeesActual) ?? FeesEstimated ?? [])
        .groupBy('Currency')
        .mapValues(vs => _(vs).sumBy('TotalFee').toFixed(2))
        .value()

    const fees_s = _(fees).toPairs().map(([currency, amount]) => `${amount} ${currency}`).join(', ')
    
    return {fees, fees_s}
}

// TODO: Is this correct???
// This is used in `isUndecided`. Maybe also that check is wrong? It should be undecided, if the due date is still after the hard instruction cutoff. Not before...
export function calculateHardInstructionCuttoff() {
    return createToday(getLocalTimeZone()).add({ months: 1 }).toString()
}

export function isUndecided(maintenanceAction: MaintenanceAction, instruction: PcInstruction | undefined, hardInstructionCuttoff: string): boolean {
    if (maintenanceAction.PermanentOrder) {
        return (
            (maintenanceAction.Phase === 'Waiting For Instruction' && instruction?.instruction !== 'Pay') ||
            (maintenanceAction.Phase === 'Auto-Pay in Progress' && instruction === undefined)
            // for Bas (might need to be deleted in future)
            || (maintenanceAction.Phase === 'Instructed / In Progress' && instruction === undefined && maintenanceAction.DueDate < hardInstructionCuttoff )
        )
    } else {
        //console.log({internalReference: maintenanceAction.IpRightInfo.CustomerReference, maintenanceAction, instruction})
        return (maintenanceAction.Phase === 'Waiting For Instruction' && instruction === undefined)
    }
}

export function createClaimScopeGroups<T extends {member: Member}>(maintenances: T[], claimScopeIdsByMemberId: { [key: number]: number[] }) {
    const onlyFamily = []
    const claimScopes = {} as { [key: string]: { claimScopeIds: number[], members: T[] } }
    for (const maintenance of maintenances) {
        const claimScopeIds = claimScopeIdsByMemberId[maintenance.member.familyMemberId]
        if (claimScopeIds === undefined || claimScopeIds.length === 0) {
            onlyFamily.push(maintenance)
        } else {
            const key = claimScopeIds.join('-')
            if (claimScopes[key] === undefined) {
                claimScopes[key] = { claimScopeIds, members: [] }
            }
            claimScopes[key].members.push(maintenance)
        }
    }
    return { onlyFamily, claimScopes }
}

export type AugmentedMaintenanceAction = MaintenanceAction & {
    member: Member,
    instruction?: PcInstruction,
    ipRight?: PcIpRight,
    instructionDueDate: string,
    status: DueDateStatus, 
    fees: Record<string, string>
}

export function useAugmentMaintenanceAction(includeStopped?: boolean) {
    const { calculateDueDates } = useTimeline()
    const { memberById } = useFilteredPatents(includeStopped)
    const { ipRightByDennemeyerId, instructionByDennemeyerId } = useDennemeyer()

    function augmentMaintenanceAction(maintenance: MaintenanceAction): AugmentedMaintenanceAction {
        const ipRight = ipRightByDennemeyerId[maintenance?.IpRightInfo?.DennemeyerId]
        const member = memberById[ipRight?.familyMemberId]
        const instruction = instructionByDennemeyerId[maintenance.DennemeyerId]
        const { status, instructionDueDate } = calculateDueDates(maintenance.DueDate)
        const { fees } = calcFees(maintenance)
        const m = { ...maintenance, ipRight, member, instruction, status, instructionDueDate, fees }
        return m
    }

    function augmentIpRight(ipRight: IpRight) {
        const pcIpRight = ipRightByDennemeyerId[ipRight?.DennemeyerId]
        const member = memberById[pcIpRight?.familyMemberId]
        return {...ipRight, member}
    }
    
    return {augmentMaintenanceAction, augmentIpRight}
}

const autoPayPhases: Partial<Record<MaintenanceActionPhase, string>> = {
    "Auto-Pay in Progress": "fee-will-be-paid-automatically",
    "Instructed / In Progress": "fee-will-be-paid-automatically",
    "Closed": "fee-has-been-paid-automatically",
    "Waiting For Instruction": "fee-will-not-be-paid-automatically-instruct-with-pay-to-pay",
    "Inactive": "fee-has-not-been-paid",
}

const manualPhases: Partial<Record<MaintenanceActionPhase, string>> = {
    "Waiting For Instruction": "instruct-or-the-fee-will-not-be-paid",
    "Instructed / In Progress": "instructed-this-fee-will-be-paid",
    "Closed": "fee-has-been-paid",
    "Inactive": "fee-will-not-be-paid",
}

const instructionLabels = {
    'Pay': 'ip-right-pay',
    'Cancel': 'ip-right-cancel',
    'Hold': 'ip-right-hold',
    'Skip': 'ip-right-skip',
}

export function extractStatusDescription(
    {maintenanceAction, status, instruction, t}: 
    {maintenanceAction: MaintenanceAction, status: DueDateStatus, instruction?: PcInstruction, t: (s: string, options?: object) => string}
): [string, MaintenanceActionPhase] {
    let phase = (maintenanceAction.PermanentOrder ? autoPayPhases[maintenanceAction.Phase] : manualPhases[maintenanceAction.Phase]) ?? maintenanceAction.Phase
    let icon = maintenanceAction.Phase 

    if (!maintenanceAction.PermanentOrder && status === 'too-late' && maintenanceAction.Phase === 'Waiting For Instruction') {
        phase = 'fee-will-not-be-paid'
        icon = 'Inactive'
    }

    if (instruction !== undefined) {
        phase = t('instructed-on', {date: instruction.created.slice(0, 10)}) + ': ' + t(instructionLabels[instruction.instruction])
        if (instruction.instruction === 'Pay') {
            icon = 'Closed'
        } else if (instruction.instruction === 'Skip' || instruction.instruction === 'Cancel') {
            icon = 'Inactive'
        } else if (instruction.instruction === 'Hold') {
            icon = 'Waiting For Instruction'
        }
    } else {
        phase = t(phase)
    }

    return [phase, icon]
}

export function statusDescription(status: RenewalState, t: (s: string, options?: object) => string) {
    return status === 'ready-at-payment-provider'
        ? t('renewals-status-desc.ready-at-payment-provider')
        : `${t('renewals-status-desc.not-yet-ready')} (${t(`renewals-status-desc.${status}`)})`
}

export function instructionHistoryDescription(
    {maintenanceActions, instructionByDennemeyerId, calculateDueDates, includeOpen = true, t}:
    {
        maintenanceActions: MaintenanceAction[],
        instructionByDennemeyerId: Record<string, PcInstruction>,
        calculateDueDates: (dueDate: string) => {instructionDueDate: string, status: DueDateStatus} | undefined,
        includeOpen?: boolean,
        t: (s: string, options?: object) => string,
    }) {
    const action = calculateNextDay(maintenanceActions, calculateDueDates, instructionByDennemeyerId)
    const openAction = action?.openAction
    const lastAction = action?.lastAction
    let description = ''
    //console.log({action})
    //console.log({maintenanceActions, action})
    if (includeOpen && openAction?.instructionDueDate) {
        description = `${firstUpperCase(t("next-instruction-due", {duedate: openAction.instructionDueDate}))}${openAction.Annuity ? `; ${t('annuity')}: ${openAction.Annuity}` : ''}`
    } else if (lastAction) {
        //console.log({lastAction})
        const [phase] = extractStatusDescription({status: lastAction.status, instruction: lastAction.instruction, maintenanceAction: lastAction, t})
        //console.log({phase})
        description = phase
    }
    return description
}

export function calculateNextDay(
    maintenanceActions: MaintenanceAction[] | undefined,
    calculateDueDates: (dueDate: string) => {instructionDueDate: string, status: DueDateStatus} | undefined,
    instructionByDennemeyerId: Record<string, PcInstruction>,
) {
    if (!maintenanceActions || maintenanceActions.length === 0) return undefined

    const _latestAction = _.maxBy(maintenanceActions, ma => ma.DueDate)
    const latestAction = {..._latestAction, ...calculateDueDates(_latestAction.DueDate)}
    const instruction = instructionByDennemeyerId[latestAction.DennemeyerId]

    const cutoff = calculateHardInstructionCuttoff()
    const today = new Date().toISOString().split('T')[0]
    const isOpen = isUndecided(latestAction, instruction, cutoff) && latestAction.DueDate >= today

    if (isOpen)
        return {openAction: latestAction}
    else
        return {lastAction: {...latestAction, instruction}}
}
