import { Formik, Form, Field } from 'formik'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { epoLoad, importExcelPortfolio } from '../backend'
import Modal from '../components/Modal'
import { patent_family, agent_link } from '../data'
import { useMessages } from '../Messages'
import { IconLoad } from '../components/icons'
import { useBackend } from '../BackendProvider'
import { familyUrl } from './utils'
import { usePatents } from './PatentsProvider'

function displayName({ firstName, lastName, name, agentType }) {
    if (agentType === 'person')
        return `${firstName} ${lastName}`
    else
        return name
}


export function EpoImportFamily() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { setErrorMessage } = useMessages()
    const { families, reload } = usePatents()
    const { entityOperation, linkOperation } = useBackend()

    const existingFamilyReferences = new Set(families.map(f => f.internalReference))

    const [portfolio, setPortfolio] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    //console.log({portfolio})

    const agentsById = _.keyBy(portfolio?.agents ?? [], 'agentId')
    const inventorsById = _(portfolio?.agentLinks ?? [])
        .filter(l => l.linkType === 'inventor')
        .groupBy('familyMemberId')
        .value()
    const applicantsById = _(portfolio?.agentLinks ?? [])
        .filter(l => l.linkType === 'applicant')
        .groupBy('familyMemberId')
        .value()

    const family = portfolio?.families?.[0]
    const members = _(portfolio?.members ?? [])
        .map(m => {
            const inventors = _(inventorsById[m.familyMemberId] ?? []).map(l => displayName(agentsById[l.agentId])).join(', ')
            const applicants = _(applicantsById[m.familyMemberId] ?? []).map(l => displayName(agentsById[l.agentId])).join(', ')
            return {...m, inventors, applicants}
        })
        .value()

    const initialValues = {members: _(members).map(m => [m.internalReference, true]).fromPairs().value(), ...family}
    //console.log({initialValues})

    function importPortfolio({members: selected, summary, familyName, internalReference}) {
        //console.log({selected})
        setIsLoading(true)
        const families = [{...family, summary, familyName, internalReference}]
        const p = {...portfolio, families, members: members.filter(m => selected[m.internalReference] ?? false)}
        importExcelPortfolio(p)
            .then(() => Promise.all([
                entityOperation('agent', 'get'),
                reload(),
                linkOperation(agent_link, 'get'),
            ]))
            .then(() => navigate(familyUrl({internalReference})))
            .catch(err => setErrorMessage(err.message))
            .finally(() => setIsLoading(false))
    }

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('import-family-from-epo')} | Patent Cockpit</title>
        </Helmet>
        <div className='portfolio-menu'>
            <h2>{t('import-family-from-epo')}</h2>
        </div>
        <div className="main-content">
            {portfolio === undefined
                ? <Modal>
                    <Formik
                        initialValues={{ publicationNumber: '', internalReference: '' }}
                        onSubmit={(values, {setStatus}) =>
                            epoLoad({ ...values, "type": patent_family })
                                .then(setPortfolio)
                                .catch(err => {
                                    console.warn(err.message)
                                    setStatus(t('epo-nothing-found'))
                                })}
                        validate={values => {
                            const errors = {}
                            if (typeof values.internalReference !== 'string' || values.internalReference.length === 0)
                                errors['internalReference'] = t('reference-required')
                            else if (existingFamilyReferences.has(values.internalReference?.trim()))
                                errors['internalReference'] = t('reference-used')
                            return errors
                        }}
                    >{({ errors, touched, status }) => 
                        <Form>
                            <div className='flex flex-col gap-2 p-4 w-fit'>
                                <h3 className='mb-2'>{t('import-family-from-epo')}</h3>
                                <label className='w-full'>
                                    <span className='label mb-1'>{t('internalReference')}</span>
                                    <Field name="internalReference" className='form-input w-full' type="text" required />
                                    {errors.internalReference && touched.internalReference && 
                                        <div className='text-red-600 text-sm py-1'>{errors.internalReference}</div>
                                    }
                                </label>
                                <label className='w-fit mb-2'>
                                    <span className='label mb-1 pr-1'>{t('publicationNumber')} / {t('patentNumber')}</span>
                                    <Field name="publicationNumber" className='form-input w-full' type="text" required />
                                </label>
                                {status &&
                                    <div className='text-red-600 border-l-2 border-red-500 text-sm p-2 w-full'>{status}</div>
                                }
                            </div>
                            <div className='bg-pcx-200 flex flex-row-reverse gap-4 p-4'>
                                <button className='btn-primary' type="submit">{t('load-from-epo')}</button>
                                <Link to=".." className='btn-secondary'>{t('cancel')}</Link>
                            </div>
                        </Form>
                    }</Formik>
                </Modal>
                : family !== undefined
                    ? <Formik
                        initialValues={initialValues}
                        onSubmit={(values) => importPortfolio(values)}
                    >
                        <Form className='max-w-lg'>
                            <div className='flex flex-col items-start sm:flex-row-reverse gap-2 sm:gap-4 pb-8'>
                                <button className='btn-primary' disabled={isLoading} type="submit">{isLoading ? <IconLoad /> : t('import')}</button>
                                <button className='btn-secondary' type="button" onClick={() => setPortfolio(undefined) }>{t('try-again')}</button>
                                <Link to=".." className='btn-secondary'>{t('cancel')}</Link>
                            </div>
                            <FamilyToLoad />
                            <MembersTable members={members} />
                        </Form>
                    </Formik>
                    : <NotFound setPortfolio={setPortfolio} />
            }
        </div>
    </>
}

function FamilyToLoad() {
    const {t} = useTranslation()
    return (
        <div className='max-w-lg pb-8'>
            <div className='flex flex-col gap-2'>
                <label className='w-full'>
                    <div className='label mb-1'>{t('internalReference')}</div>
                    <Field name="internalReference" className='form-input w-full' />
                </label>

                <label className='w-full'>
                    <div className='label mb-1'>{t('familyName')}</div>
                    <Field name="familyName" className='form-input w-full' />
                </label>

                <label className='w-full'>
                    <div className='label mb-1'>{t('summary')}</div>
                    <Field name="summary" as="textarea" className="form-textarea h-48 w-full" />
                </label>

            </div>

        </div>
    )
}

function booleanRender(v: any, t: (s: string) => string) {
    return typeof v === 'boolean' ? t(v ? 'yes' : 'no') : ''
}

const fields = [
    {field: "internalReference"},
    {field: "countryCode"},
    {field: "title"},
    {field: "applicationDate"},
    {field: "applicationNumber"},
    {field: "publicationDate"},
    {field: "publicationNumber"},
    {field: "patentDate"},
    {field: "patentNumber"},
    {field: "familyMemberStatus"},
    {field: "ipType"},
    {field: "numberClaims"},
    {field: "expiryDate"},
    {field: "patentOfficeLink"},
    {field: "firstFiling", render: booleanRender},
    {field: "pctRouteFiling", render: booleanRender},
    {field: "unitaryPatent", render: booleanRender},
    {field: "validated", render: booleanRender},
    {field: "inventors"},
    {field: "applicants"},
]

function MembersTable({members}) {
    const { t } = useTranslation()
    const cellStyle = 'px-1 border border-pcx-200'
    const backupRender = (v: any) => v ?? ""
    //console.log({members})
    return <>
        <h4>{t('family-members')}</h4>
        <table className='mt-4 text-sm text-slate-600 whitespace-nowrap'>
            <thead className='text-left font-normal'>
                <tr>
                    <th className={cellStyle}>{t('select')}</th>
                    {fields.map(({field}) =>
                        <th key={field} className={cellStyle}>{t(field)}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {_(members)
                    .sortBy('internalReference')
                    .map((member, i) =>
                        <tr key={i}>
                            <td className={cellStyle + ' text-center'}>
                                <Field type="checkbox" name={`members.${member.internalReference}`} className='form-checkbox' />
                            </td>
                            {fields.map(({field, render}) =>
                                <td key={field} className={cellStyle}>{(render ?? backupRender)(member[field], t)}</td>
                            )}
                        </tr>
                    )
                    .value()}
            </tbody>
        </table>
    </>
}

function NotFound({setPortfolio}) {
    const { t } = useTranslation()
    return (
        <div className='max-w-lg'>
            <h3>{t('family-not-found')}</h3>
            <div>
                <button className='btn-primary' onClick={() => setPortfolio(undefined)}>{t('try-again')}</button>
                <Link to=".." className='btn-secondary'>{t('cancel')}</Link>
            </div>
        </div>
    )
}