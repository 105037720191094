import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import _, { Dictionary } from 'lodash'

import { useMessages } from "../Messages"
import { fromExcelDate } from "../utils/dates"
import { emptyStringAsUndefined } from "../utils/strings"
import { createExcelFile, parseExcelFile } from "../backend"
import { IconSpinner } from "../components/icons"
import { CostItem, CostLink, useCosts } from "./CostsProvider"
import { Member } from "../patents/patents"
import { family_member } from "../data"
import { usePatents } from "../patents/PatentsProvider"
import { importFields } from "./utils"


export default function CostsImport() {
    const {t} = useTranslation()

    const { setErrorMessage } = useMessages()
    const { memberByReference, memberById } = usePatents()
    const { costById, costsByMemberId: costsPerMemberId, linkByCostId, postCost, postCostLink } = useCosts()
    const navigate = useNavigate()

    const [importRows, setImportRows] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)

    function createLink(internalReference: string) {
        if (internalReference in memberByReference) {
            const member = memberByReference[internalReference]
            return {
                entityId: member.familyMemberId,
                entity: family_member,
            }
        } else {
            return undefined
        }
    }

    function getCostId(internalReference: string, reference: string) {
        return costsPerMemberId[memberByReference[internalReference]?.familyMemberId ?? -1]
            ?.find(c => c.reference === reference)
            ?.costId
    }

    function handleSubmit(e) {
        const file = e.target.files[0];
        parseExcelFile(file, true)
            .then(res => setImportRows((res?.sheets?.[0]?.rows ?? []).map((row: ImportRow) => {
                const costId = getCostId(row.familyMemberReference, row.invoiceNumber)
                const existingRow = toRow(costId, costById, linkByCostId, memberById)
                if (existingRow) {
                    existingRow['overwrites'] = _(importFields)
                        .map(({name}) => existingRow[name] !== row[name] && [name, existingRow[name]])
                        .filter(Boolean)
                        .fromPairs().value()
                }
                const date = typeof row.date === 'number' ? fromExcelDate(row.date) : row.date
                const invoiceNumber = typeof row.invoiceNumber === 'number' ? '' + row.invoiceNumber : row.invoiceNumber
                return {
                    ...row,
                    date, 
                    invoiceNumber,
                    link: createLink(row.familyMemberReference),
                    costId,
                    existingRow,
                }
            })))
            .catch(err => setErrorMessage(err.message))
    }

    async function handleImport(rows: ExtendedImportRow[]) {
        setIsLoading(true)

        return Promise.all(
            rows.map(async row => {
                const [cost, link] = fromRow(row)
                const c = await postCost(cost)
                if (!cost.costId) {
                    await postCostLink({costId: c.costId, ...link})
                }
            })
        ).then(() => {
            setIsLoading(false)
            navigate('/patents/costs')
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const exampleExcel = {
        sheets: [
            {
                name: 'Costs',
                header: importFields.map(f => f.name),
                rows: exampleCostsFile,
            },
            {
                name: t('comments'),
                header: ['reference', 'required'],
                rows: importFields,
            }
        ]
    }

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('excel-import')} | {t('costs')} | Patent Cockpit</title>
            </Helmet>
            <div className="portfolio-menu">
                <h2 className="">
                    {t('excel-import')}
                </h2>
            </div>
            <div className="p-4 bg-white overflow-auto grow">
                <div className="hidden only:block">
                    <div className="flex flex-row-reverse gap-2 w-fit">
                        <label className='btn-primary'>{t('upload-costs-file')}
                            <input
                                type="file"
                                accept=".xlsx"
                                onChange={handleSubmit}
                                style={{
                                    clip: "rect(0 0 0 0)",
                                    clipPath: "inset(50%)",
                                    height: "1px",
                                    overflow: "hidden",
                                    position: "absolute",
                                    whiteSpace: "nowrap",
                                    width: "1px",
                                }} />
                        </label>

                        <button 
                            className="btn-secondary" 
                            onClick={() => 
                                createExcelFile(exampleExcel, 'ExampleCostsImport.xlsx')
                                    .catch(err => setErrorMessage(err.message))
                            }
                        >
                            {t('example-file')}
                        </button>
                        <Link className="btn-secondary" to="/patents/costs">{t('cancel')}</Link>
                    </div>
                    <div className="py-4">
                        <div className="info">
                            <p>{t('upload-tip-size')}</p>
                            <p>{t('upload-tip-formulas')}</p>
                            <ol>
                                <li>{t('upload-tip-formulas-remedy1')}</li>
                                <li>{t('upload-tip-formulas-remedy2')}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {importRows && <ImportCostsTable {...{importRows, handleImport, isLoading}} />}
            </div>
            <Outlet />
        </>
    )
}



function ImportCostsTable(
    {importRows, handleImport, isLoading}: 
    {importRows: ExtendedImportRow[], handleImport: (rows: ExtendedImportRow[]) => void, isLoading: boolean}
) {
    const {t} = useTranslation()

    const [deselected, setDeselected] = useState({})

    //console.log({importRows})

    function doImport(overwrite: boolean) {
        handleImport(importRows.filter((row, ri) => row.link && (overwrite || !deselected[ri])))
    }

    const basicCellStyle = "px-2 border whitespace-nowrap text-sm border-pcx-300/50"
    const cellStyle = basicCellStyle + ""
    const cellNewStyle = basicCellStyle + " bg-pcx-200"
    const cellOverwriteStyle = basicCellStyle + " bg-warn-200"
    const headerStyle = cellStyle + ' font-medium text-left'

    return <>
        <div className="flex gap-2 max-w-4xl mb-4">
            <h3 className="grow">{t('data-import-h3')}</h3>
            <Link to="/patents/costs" className="btn-secondary whitespace-nowrap">
                {t('cancel')}
            </Link>
            <button onClick={() => doImport(false)} className="btn-primary inline-flex gap-2 items-center whitespace-nowrap" >
                {t('import-only-new')} {isLoading && <IconSpinner className="h-5 w-5 animate-spin" />}
            </button>
            <button onClick={() => doImport(true)} className="btn-warn inline-flex gap-2 items-center whitespace-nowrap" >
                {t('import-and-overwrite')} {isLoading && <IconSpinner className="h-5 w-5 animate-spin" />}
            </button>
        </div>
        <div className="py-2 flex flex-row gap-2">
            <div className={cellStyle}>{t('new-value-new-row')}</div>
            <div className={cellNewStyle}>{t('new-value-existing-row')}</div>
            <div className={cellOverwriteStyle}>{t('overwrite-value-existing-row')}</div>
        </div>
        <table>
            <thead>
                <tr>
                    <th className={headerStyle}>{t('status')}</th>
                    <th className={headerStyle}>{t('import')}?</th>
                    {importFields.map(f => <th key={f.name} className={headerStyle}>{t(f.name)}</th>)}
                </tr>
            </thead>
            <tbody>
                {importRows.map((row: ExtendedImportRow, ri: number) => {

                    const existingRow = row.existingRow
                    const state = row.costId ? 'existing' : row.link ? 'new' : 'reference-not-valid'

                    function renderField(field: string) {
                        // no existing or the same
                        if (!existingRow || existingRow[field] === row[field])
                            return <td key={field} className={cellStyle}>
                                {row[field]}
                            </td>
                        else {
                            // is new, is different, is same
                            const isOverwrite = existingRow.overwrites[field] !== undefined
                            return <td key={field} className={isOverwrite ? cellOverwriteStyle : (emptyStringAsUndefined(row[field]) ? cellNewStyle : cellStyle)} title={existingRow[field]}>
                                {row[field]}
                            </td>
                        }
                    }

                    const selected = row.link && !deselected[ri]

                    return <tr key={ri} className={clsx(!selected && 'text-slate-400')}>
                        <td className={clsx(cellStyle, 'capitalize')}>{t(state)}</td>
                        <td className={cellStyle}>
                            <input type="checkbox"
                                className="form-checkbox mx-auto"
                                checked={selected}
                                disabled={!row.link}
                                onChange={e => setDeselected({ ...deselected, [ri]: !e.target.checked })} />
                        </td>
                        {importFields.map(f => renderField(f.name))}
                    </tr>
                })}
            </tbody>
        </table>
    </>
}

type ImportRow = {
    date: string;
    amount: number;
    vat: number;
    currency: string;
    invoiceNumber: string;
    costCenter?: string;
    comment: string;
    familyMemberReference: string;
}

type ExtendedImportRow = ImportRow & {
    costId?: number, 
    link?: {entity: string, entityId: number},
    existingRow?: ImportRow & {overwrites: Dictionary<any>},
}

function toRow(costId: number | undefined, costById: Record<number, CostItem>, linkByCostId: Record<number, CostLink>, memberById: Record<number, Member>): ExtendedImportRow | undefined {
    if (!costId) return undefined
    const cost = costById[costId]
    const member = memberById[linkByCostId[costId]?.entityId]
    const familyMemberReference = member?.internalReference
    return familyMemberReference && {
        ...cost,
        costId,
        invoiceNumber: cost.reference,
        familyMemberReference,
    }
}

function fromRow(row: ExtendedImportRow): [CostItem, {entity: string, entityId: number}] {
    const {link, ...cost} = row
    return [{...cost, reference: cost.invoiceNumber}, link]
}


const exampleCostsFile: ImportRow[] = [
    {
        date: '2021-01-01',
        amount: 100,
        vat: 0,
        currency: 'EUR',
        invoiceNumber: 're-007',
        costCenter: 'CC-1',
        comment: 'Comment',
        familyMemberReference: 'P001-DE',
    },
    {
        date: '2022-04-02',
        amount: 200,
        vat: 30,
        currency: 'CHF',
        invoiceNumber: 're-021',
        costCenter: 'CC-1',
        comment: 'Comment',
        familyMemberReference: 'P002-US',
    },
    {
        date: '2023-06-02',
        amount: 300,
        vat: 40,
        currency: 'CHF',
        invoiceNumber: 're-022',
        costCenter: 'CC-2',
        comment: '',
        familyMemberReference: 'P002-US',
    },
]