import _ from "lodash";

import { Member } from "../patents/patents";
import { missingInformation } from "./check";
import { Instruction, IpRight, MaintenanceAction, MaintenanceActionPhase, PcInstruction, PcIpRight, ValidationMessage } from "./DennemeyerProvider";
import { DueDateStatus, isOpen } from "./settings/instruction_timeline";

// Preparing functions for Notion Flow Chart 
// https://www.notion.so/patent-cockpit/Flow-Charts-for-Renewal-States-566df9d644f34769a7b66f368526600c?pvs=4

export type RenewalStatus =
    //'pct-not-importable' | 
    'ignored' |
    'stopped' |
    'can-import' |
    'missing-data' |
    'has-errors' |
    'ready' |
    'paid-for-life' |
    'back-office-processing'

export type RenewalStatusInfo = IgnoredInfo | GeneralRenewalStatus | MissingInfo

export interface GeneralRenewalStatus {
    status: Exclude<RenewalStatus, 'ignored' | 'missing-data'>,
}

export interface MissingInfo {
    status: 'missing-data',
    missing: string[],
}

export interface IgnoredInfo {
    status: 'ignored',
    reason: string,
}

export function renewalStatusToString(status: RenewalStatusInfo, t: (key: string, options?: object) => string): string {
    if (status.status === 'ignored') {
        return `${t(status.status)} (${t(status.reason)})`
    } else {
        return t(status.status)
    }
}

export function isImported(renewalStatus: RenewalStatus, ipRight?: IpRight) {
    return ipRight !== undefined || ['missing-data', 'has-errors', 'ready', 'paid-for-life', 'back-office-processing'].includes(renewalStatus)
}

export function isNotImportable(renewalStatus: RenewalStatusInfo) {
    return (renewalStatus.status === 'ignored') &&
        (renewalStatus.reason !== 'manually-ignored')
    //return ['pct-not-importable', 'ignored'].includes(renewalStatus)
}

export function renewalStatusExtraction(
    {member, pcIpRight, ipRight, validationErrors, isIgnored}:
    {member: Member, pcIpRight?: PcIpRight, ipRight?: IpRight, validationErrors?: ValidationMessage[], isIgnored: (id: number) => boolean}
): RenewalStatusInfo {
    if (isIgnored(member.familyMemberId)) {
        return {status: 'ignored', reason: 'manually-ignored'}
    } else if (member.countryCode === 'WO') {
        return {status: 'ignored', reason: 'pct-not-importable'}
    } else if (pcIpRight?.dennemeyerId) {
        return {status: differentiateImportState({member, pcIpRight, ipRight, validationErrors})} as GeneralRenewalStatus
    } else if (member.familyMemberStatus === 'in-preparation') {
        return {status: 'ignored', reason: 'in-preparation'}
    } else if (member.familyMemberStatus === 'stopped' || pcIpRight?.status === 'Inactive' || ipRight?.Status === 'Inactive') {
        return {status: 'stopped'}
    } else if (member.countryCode === 'EP') {
        // EP differentiation
        if (member.familyMemberStatus === 'granted' && member.unitaryPatent !== true) {
            return {status: 'ignored', reason: 'granted-ep'}
        } else {
            return {status: 'can-import'}
        }
    } else if (member.familyMemberStatus !== 'granted') {
        return {status: 'ignored', reason: 'not-granted'}
    } else {
        const missing = missingInformation(member)
        if (missing.length > 0) {
            return { status: 'missing-data', missing }
        } else {
            return { status: 'can-import' }
        }
    }
}

function differentiateImportState({member, pcIpRight, ipRight, validationErrors}: {member: Member, pcIpRight: PcIpRight, ipRight?: IpRight, validationErrors?: ValidationMessage[]}): RenewalStatus {
    if (validationErrors?.length > 0) {
        return 'has-errors' // TODO return the messages
    } else if (pcIpRight.status === 'Inactive') {
        return 'stopped'
    } else if (ipRight === undefined) {
        return 'back-office-processing'
    } else if (ipRight?.NextMaintenanceActionDueDate !== undefined && ipRight?.NextMaintenanceActionDueDate === ipRight?.ExpiryDate) {
        return 'paid-for-life'
    } else {
        // TODO: should we also check ipRight?
        return 'ready'
    }
}

export function isMaintenanceActionPhaseOpen(phase: MaintenanceActionPhase) {
    return phase === "Auto-Pay in Progress" || phase === "Waiting For Instruction"
}


function partitionOpenClosedMAs({maintenanceActions, calculateDueDates}: {maintenanceActions?: MaintenanceAction[], calculateDueDates: (dueDate: string) => {instructionDueDate: string, status: DueDateStatus} | undefined}) { 
    const [open, closed] = _(maintenanceActions ?? [])
        .map((ma: MaintenanceAction) => ({...ma, ...(calculateDueDates(ma.DueDate) ?? {})}))
        .partition(ma => isOpen(ma.status) && isMaintenanceActionPhaseOpen(ma.Phase))
        .value()
    //console.log({maintenanceActions, open, closed})
    return {open, closed}
}

export type MaintenanceActionHistoryStatus = 
    'no-history' | 'not-ready' | 'unknown' | 'instructed' |
    'payment-will-be-triggered' | 'payment-has-been-triggered' |
    'fee-will-not-be-paid-unless-instructed' | 'fee-will-not-be-paid' | 'fee-has-not-been-paid' 
    //'fee-has-not-been-paid' | 'fee-will-not-be-paid-unless-instructed' | 'fee-will-not-be-paid' | 'payment-will-be-triggered' | 'payment-has-been-triggered' | 'pay-instructed' | 'cancel-instructed' | 'unknown-instruction'

export interface MaintenanceActionHistory {
    status: MaintenanceActionHistoryStatus,
    open?: number,
    total?: number,
    instruction?: Instruction,
    date?: string,
}

export function maintenanceActionHistoryToString(history: MaintenanceActionHistory, t: (key: string, options?: object) => string): string {

    const instructions_s = () =>
        (history.open > 0)
            ? t('ma.instructions-y-open', { open: history.open, count: history.total })
            : t('ma.instructions', { count: history.total })
    
    const instructed_s = () =>
        (history.instruction === 'Pay')
            ? t('ma.instructed-pay-on', history)
            : (history.instruction === 'Cancel')
            ? t('ma.instructed-cancel-on', history)
            : t('ma.unknown-instruction', history)

    switch (history.status) {
        case 'no-history': return t('no-history')
        case 'not-ready': return instructions_s()
        case 'unknown': return instructed_s()
        case 'instructed': return instructed_s()
        case 'payment-will-be-triggered': return t('ma.payment-will-be-triggered-on', history)
        case 'payment-has-been-triggered': return t('ma.payment-has-been-triggered-on', history)
        case 'fee-will-not-be-paid-unless-instructed': return t('ma.fee-payment-no-instructed-yet')
        case 'fee-will-not-be-paid': return t('ma.fee-will-not-be-paid')
        case 'fee-has-not-been-paid': return t('ma.fee-has-not-been-paid')
    }
}


export function maintenanceActionsStatus({
    renewalStatus, maintenanceActions, calculateDueDates, instructionByDennemeyerId,
}: {
    renewalStatus: RenewalStatus, 
    maintenanceActions?: MaintenanceAction[],
    calculateDueDates: (dueDate: string) => {instructionDueDate: string, status: DueDateStatus} | undefined,
    instructionByDennemeyerId: Record<string, PcInstruction>,
}): MaintenanceActionHistory {
    if ((maintenanceActions ?? []).length === 0) {
        return {status: 'no-history'}
    } else if (renewalStatus !== 'ready') {
        const { open, closed } = partitionOpenClosedMAs({ maintenanceActions, calculateDueDates })
        return {status: 'not-ready', open: open.length > 0 ? open.length : undefined, total: closed.length + open.length}
    } else {
        const lastMaintenanceAction = _.maxBy(maintenanceActions, ma => ma.DueDate)
        const dueDate = calculateDueDates(lastMaintenanceAction.DueDate)
        const maintenanceAction = {...lastMaintenanceAction, ...(dueDate ?? {})}
        return {
            ...maintenanceActionStatus({ maintenanceAction, instruction: instructionByDennemeyerId[lastMaintenanceAction.DennemeyerId]}), 
            total: maintenanceActions.length}
    }
}

function maintenanceActionStatus(
    { maintenanceAction, instruction }: 
    { maintenanceAction: MaintenanceAction & {instructionDueDate?: string, status?: DueDateStatus}, instruction: PcInstruction }
): MaintenanceActionHistory {

    // if there is no instruction, let's not consider it as instructed although DM says so
    const isInstructed = (maintenanceAction.Phase === "Instructed / In Progress" && (instruction !== undefined || maintenanceAction.InstructionInfo !== undefined)) 
        || maintenanceAction.Phase === "Closed" || instruction !== undefined

    function instructedString(): MaintenanceActionHistory {
        if (maintenanceAction.InstructionInfo?.Instruction === 'Pay' || instruction?.instruction === 'Pay') {
            return {status: 'instructed', instruction: 'Pay', date: (maintenanceAction.InstructionInfo?.InstructedAt ?? instruction?.created)?.slice(0, 10)}
        } else if (maintenanceAction.InstructionInfo?.Instruction === 'Cancel' || instruction?.instruction === 'Cancel') {
            return {status: 'instructed', instruction: 'Cancel', date: (maintenanceAction.InstructionInfo?.InstructedAt ?? instruction?.created)?.slice(0, 10)}
        } else {
            return {status: 'unknown', date: (maintenanceAction.InstructionInfo?.InstructedAt ?? instruction?.created)?.slice(0, 10)}
        }
    }

    if (maintenanceAction.PermanentOrder) { // auto-pay
        if (isInstructed) {
            return instructedString()
        } else {
            const today = new Date().toISOString().slice(0, 10)
            const isPast = maintenanceAction.instructionDueDate <= today
            return { status: isPast ? 'payment-has-been-triggered' : 'payment-will-be-triggered', date: maintenanceAction.instructionDueDate }
        }
    } else {
        if (isInstructed) {
            return instructedString()
        } else {
            if (maintenanceAction?.status === 'open' || maintenanceAction?.status === 'early' || maintenanceAction?.status === 'critical') {
                return {status: 'fee-will-not-be-paid-unless-instructed'}
            } else if (maintenanceAction?.status === 'manual-instruction') {
                return {status: 'fee-will-not-be-paid'}
            } else {
                return {status: 'fee-has-not-been-paid'}
            }
        }
    }
}

export const renewalChangeModalStr = 'renewal-change'
export type RenewalChange = 'can-be-imported' | 'has-been-stopped' | undefined

export function canBeImported(
    {oldMember, newMember, pcIpRight, ipRight, isIgnored}:
    {oldMember?: Member, newMember: Member, pcIpRight: PcIpRight, ipRight: IpRight, isIgnored: (id: number) => boolean, validationErrors?: ValidationMessage[]}) {
    const newStatus = renewalStatusExtraction({member: newMember, pcIpRight, ipRight, isIgnored})
    //console.log({newStatus})
    if (newStatus.status === 'can-import') {
        if(oldMember === undefined) {
            return true
        }
        const oldStatus = renewalStatusExtraction({member: oldMember, pcIpRight, ipRight, isIgnored})
        //console.log({oldStatus, result: !(oldStatus.status === 'can-import' || isImported(oldStatus.status, ipRight)), imported: isImported(oldStatus.status, ipRight)})
        return !(oldStatus.status === 'can-import' || isImported(oldStatus.status, ipRight))
    } else {
        return false
    }
}

export function canBeStopped(
    {oldMember, newMember, pcIpRight, ipRight, isIgnored, validationErrors}: 
    { oldMember?: Member, newMember: Member, pcIpRight?: PcIpRight, ipRight?: IpRight, isIgnored: (id: number) => boolean, validationErrors?: ValidationMessage[] }) {

    if (oldMember?.familyMemberId === undefined) return false

    const oldStatus = renewalStatusExtraction({ member: oldMember, pcIpRight, ipRight, isIgnored, validationErrors })
    return (isImported(oldStatus.status, ipRight) && oldStatus.status !== 'stopped' && newMember.familyMemberStatus === 'stopped')
}