import { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import _ from 'lodash'

import { TriState } from '../products/ClaimScopesMapping'
import { TrademarkProductMapping, useTrademarks } from './TrademarksProvider'
import { linkDifference } from '../BackendProvider'
import { trademarkFamilyUrl } from './utils'
import { usePrompt } from '../components/blocker'
import { useFilteredTrademarks } from '../filter/FilteredTrademarks'
import { EmptyFilteredPortfolio, EmptyPortfolio } from './TrademarkPortfolio'
import { useProducts } from '../products/ProductsProvider'
import { commodityUrl } from '../products/products'

export default function TrademarkProductMappings() {
    const {t} = useTranslation()

    // TODO: based on filter
    const {trademarkFamilies: _families } = useFilteredTrademarks()
    const {trademarkProductMappings, deleteTrademarkProductMapping, postTrademarkProductMapping, trademarkFamilies: allFamilies} = useTrademarks()
    const { commodities: _commodities } = useProducts()

    // Working copy
    const [links, setLinks] = useState(trademarkProductMappings)
    const [isEditing, setIsEditing] = useState(false)

    usePrompt(t("leave-site-changes"), isEditing)

    // TODO memoize
    const families = _.sortBy(_families, 'reference')
    const commodities = _(_commodities)
        .groupBy(c => c.commodityClass)
        .mapValues(cs => _.sortBy(cs, 'commodityReference'))
        .toPairs()
        .sortBy(([key]) => key).value()

    async function saveDiff() {
        const [toAdd, toDelete] = linkDifference(trademarkProductMappings, links)
        return deleteTrademarkProductMapping(toDelete)
            .then(() => postTrademarkProductMapping(toAdd))
            .then(() => setIsEditing(false))
    }
    function cancel() {
        setLinks(trademarkProductMappings)
        setIsEditing(false)
    }

    if (families.length === 0 || commodities.length === 0)
        return <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('product-mapping')} | {t('trademarks')} | Patent Cockpit</title>
            </Helmet>
            <div className="header-row">
                <div className='flex flex-row items-center max-w-2xl gap-2'>
                    <h2 className='modern-h2 grow'>{t('product-mapping')}</h2>
                </div>
            </div>
            <div className="main-content grow">
                {commodities.length === 0
                    ? <Trans
                        i18nKey="add-products-hint"
                        components={{ portfoliolink: <Link className="text-pcx-500 underline-link" to="/products/portfolio" /> }}
                    />
                    : allFamilies?.length > 0
                    ? <EmptyFilteredPortfolio />
                    : <EmptyPortfolio />}
            </div>
        </>
    else
    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('product-mapping')} | {t('trademarks')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row">
            <div className='flex flex-row items-center max-w-2xl gap-2'>
                <h2 className='modern-h2 grow'>{t('product-mapping')}</h2>
                {isEditing
                    ? <>
                        <button className='text-sm btn-primary' onClick={() => saveDiff()}>{t('save')}</button>
                        <button className='text-sm btn-secondary' onClick={() => cancel()}>{t('cancel')}</button>
                    </>
                    : <button className='text-sm btn-secondary' onClick={() => setIsEditing(true)}>{t('edit')}</button>}
            </div>
        </div>
        <div className="main-content grow">
            <table className='relative w-fit border-collapse'>
                <thead className=''>
                    <tr className='sticky -top-4 z-20 shadow-[0_2px_2px_0_rgb(0_0_0_/_0.1)] text-left'>
                        <th className='w-full h-[52px] pb-px bg-white dark:bg-pcx-100 '>
                            <div className='sticky -top-4 -left-4 z-40 w-full h-full bg-white dark:bg-pcx-100' />
                        </th>
                        {families.map(family =>
                            <th key={'f-' + family.familyId} className='py-px bg-white dark:bg-pcx-100 font-normal max-w-[8rem]'>
                                <div className='bg-white dark:bg-pcx-100 px-2 pb-2 '>
                                    <Link className='underline-link whitespace-nowrap' to={trademarkFamilyUrl(family)}>{family.reference}</Link>
                                    <p className='text-xs truncate'>{family.name}</p>
                                    {/*<TmImage />*/}
                                </div>
                            </th>)}
                    </tr>
                </thead>
                <tbody>
                    {commodities.map(([commodityClass, perClass], ci) =>
                        <Fragment key={'frag-' + commodityClass}>
                            <tr key={'cl-' + commodityClass} className="border-t-2 border-pcx-300">
                                <td className='text-pcx-500/80 font-medium pl-1 sticky -left-4'>
                                    <div className=' bg-white dark:bg-pcx-100 whitespace-nowrap'>
                                        {commodityClass !== "undefined" ? commodityClass : t("unclassified")}
                                    </div>
                                </td>
                                {families.map((f, fi) => <td key={`${commodityClass}-${fi}`} />)}
                            </tr>
                            {perClass.map(commodity =>
                                <tr key={'c-' + commodity.commodityId} className='border-t border-pcx-200'>
                                    <td className='sticky -left-4 py-0.5'>
                                        <Link
                                            className='underline-link block pl-3  bg-white dark:bg-pcx-100'
                                            to={commodityUrl(commodity)}
                                        >{commodity.commodityReference}</Link>
                                    </td>

                                    {families.map(family =>
                                        <td key={`c-f-${commodity.commodityId}-${family.familyId}`} className='px-2 text-center'>
                                            <TriState 
                                                {...{
                                                    links, 
                                                    setLinks,
                                                    baseLink: { commodityId: commodity.commodityId, familyId: family.familyId}, 
                                                    disabled: !isEditing,
                                                    isEqualLink: (a: TrademarkProductMapping, b: TrademarkProductMapping) => a.commodityId === b.commodityId && a.familyId === b.familyId,
                                                    isThirdParty: commodity.isThirdParty
                                                }}
                                            />
                                        </td>)}
                                </tr>)}
                        </Fragment>
                    )}
                </tbody>

            </table>
        </div>
    </>
}