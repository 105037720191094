import React, { useState, useEffect } from "react"
import { Outlet } from "react-router"
import { Link, useSearchParams, NavLink, useNavigate } from "react-router-dom"
import { Formik, Form, Field } from "formik"
import _ from 'lodash'

import { management, updatePassword } from "../backend"
import { useMessages } from "../Messages"
import { linkDifference } from "../BackendProvider"
import { AddUser, UserTable, CreateUser as CreateTeamUser } from "./UserManagement"
import { TeamManagementProvider, useAdminManagementContext, useUserTeams } from "../Management"
import { IconX } from "../components/icons"
import { annuities_flag, brand_flag, claim_scope_flag, documents_flag, new_innovation_flag, plans, portfolio_update_flag, product_country_flag, invention_flag, excel_import_flag, excel_export_flag, tasks_flag, costs_flag, tracking_flag, prio_sort_flag, annuities_admin_flag } from "../data"
import { DeleteButton } from "../components/edit-table"
import { SingleTeamSettings } from "./TeamSettings"
import { useUserSettingsFor } from "../user/UserSettingsProvider"
import { useTeamFlags } from "./TeamFlags"
import { postEvent } from "./events"
import { useAuth } from "../user/Auth"
import AdminDmsSettings from "../documents/admin/AdminSettings"

const areaStyling = "break-inside-avoid-column sm:w-96"
const labelStyle = "text-gray-800 pt-1"

export default function AdminPage() {

    function navLinkStyle({isActive}) {
        return isActive ? "tab-active" : "tab-nonactive"
    }

    // TODO: add utilities for finding users without team, teams without users and super-admins
    return (
        <>
            <div className="header-row flex flex-wrap h-18">
                <NavLink to="users" className={navLinkStyle}>User Management</NavLink>
                <NavLink to="teams" className={navLinkStyle}>Manage Teams</NavLink>
                <NavLink to="add-team" className={navLinkStyle}>Add Team</NavLink>
                <NavLink to="billing" className={navLinkStyle}>Billing</NavLink>
                <NavLink to="events" className={navLinkStyle}>Events</NavLink>
                <NavLink to="statistics" className={navLinkStyle}>Statistics</NavLink>
            </div>
            <div className="main-content">
                <Outlet />
            </div>
        </>
    )
}

export function UserManagement() {
    const {user: {name: myUser}} = useAuth()
    const {users, triggerReload} = useAdminManagementContext()
    const [hasLoaded, setHasLoaded] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const user = searchParams.get("user") || ""
    function setUser(user) {
        if (user === '')
            setSearchParams({})
        else
            setSearchParams({ user })
    }

    const [flags, setFlags] = useState([])

    useEffect(() => {
        if (!hasLoaded) {
            setHasLoaded(true)
            management({ type: "flag", operation: "get" })
                .then(setFlags)
                .then(() => setHasLoaded(true))
        }
    }, [hasLoaded])

    function deleteUser(user) {
        return management({type: "login", operation: "delete", user})
            .then(() => {setUser(""); return triggerReload()})
    }
    

    return (
        <div className="">
            <h3>User Management</h3>
            <div className="max-w-md py-1 flex flex-row gap-2">
                <select 
                        id="name"
                        className={"form-select w-full border border-1 pl-2 p-px h-10"}
                        value={user}
                        onChange={(e) => { setUser(e.target.value) }}
                >
                    <option key="select-user-key" value="">Select User</option>
                    {_.sortBy(users, u => u.toLowerCase())
                        .map(u => <option key={u} value={u}>{u}</option>)}
                </select>
                <button className="" onClick={() => setUser("")}><IconX /></button>
                <button className="btn-tertiary whitespace-nowrap text-sm" onClick={() => setUser(myUser)}>My User {myUser}</button>
            </div>
            {user !== "" 
                ? <div className="columns-sm gap-4 p-2 bg-pc-100" key={user}> {/* Keep key to make sure the state of the forms changes on user change */}
                    <SetPassword {...{user}} />
                    <UserFlags {...{ user, flags: flags.filter(f => f.username === user).map(f => f.flag), setHasLoaded }} />
                    <TeamsPerUser {...{ user }} />
                    <DeleteUser {...{ user, deleteUser }} />
                    <UserInfo {...{ user }} />
                </div>
                : <div className="border-b-2 border-pc-300/50 py-2 w-96" />
            }
        </div>
    )
}

function TeamsPerUser({user}) {
    const {teams, removeUserFromTeam} = useUserTeams(user)

    if (teams === undefined) return null

    return (
        <div className={areaStyling}>
            <h4>Teams associated with {user}</h4>
            <table className="mt-2 mb-4">
                <thead>
                    <tr className="text-left">
                        <th>Team</th>
                        <th className="px-2">Active?</th>
                        <th/>
                        </tr>
                </thead>
                <tbody>
                    {teams.map(({name, displayName, active}) => (
                        <tr key={name} className="align-top">
                            <td className="font-medium pb-3">{displayName
                                ? <>{displayName}<br/><span className="text-slate-500 font-normal">{name}</span></>
                                : name
                            }</td>
                            <td className="px-2">{active ? "Yes" : "No"}</td>
                            <td>
                                <DeleteButton del={() => removeUserFromTeam({userName: user, team: name})}>Remove</DeleteButton>
                                <Link to={`/settings/admin/teams?team=${encodeURIComponent(name)}`} className="btn-tertiary py-0">Edit</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export function AddTeam() {
    const {addTeam, teams, triggerReload} = useAdminManagementContext()

    const teamNames = teams.map(r => r.name)

    return (
        <div className={areaStyling}>
            <h3>Add Team</h3>
            <Formik
                initialValues={{
                    name: "",
                    displayName: "",
                    plan: "demo",
                }}
                onSubmit={(team, { resetForm }) => addTeam(team).then(() => {triggerReload(); resetForm()})}
                validate={({name}) => {
                    const errors = {}
                    if (teams.find(t => t.name === name))
                        errors['name'] = "Name is already taken!"
                    return errors
                }}
            >{({ dirty, isValid, errors: {name: nameError}, values: {name: nameValue} }) => {
                const disabled = !(dirty && isValid)
                const lcName = nameValue.toLowerCase()
                const similarTeams = lcName.length > 2 ? teamNames.filter(rn => rn.toLowerCase().includes(lcName)) : []
                return (
                    <Form className="max-w-sm flex flex-col gap-1">
                        <label htmlFor="name" className={labelStyle}>Unique Team Name</label>
                        <Field id="name" name="name" className="form-input" autoComplete="off" />
                        {nameError && <div className="py-1 text-red-800">{nameError}</div>}

                        <label htmlFor="displayName" className={labelStyle}>Display Name</label>
                        <Field id="displayName" name="displayName" className="form-input" autoComplete="off" />

                        <PlanField />

                        <div className="w-full pt-4 flex flex-row-reverse justify-between items-center">
                            <button disabled={disabled} className={!disabled ? "btn-primary" : "btn-disabled"}>Add</button>
                            {similarTeams.length > 0 &&
                                <div className="text-pc-600 whitespace-nowrap overflow-hidden text-ellipsis pr-2 text-sm">
                                    Similar Teams: {similarTeams.join(", ")}
                                </div>
                            }
                        </div>
                    </Form>
                )
            }}</Formik>
        </div>
    )
}

export function TeamManagement() {
    const {setErrorMessage} = useMessages()
    const {getTeamToken, user: {name: user}, team: myTeam} = useAuth()
    const {users, teams, triggerReload} = useAdminManagementContext()

    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const team = searchParams.get("team") ?? ""
    function setTeams(t: string) {
        setSearchParams({team: t})
    }

    //const [team, setTeams] = useState(teamParam ?? "")

    function teamLogin() {
        getTeamToken(team)
            .then(async resp => {
                try {
                    const json = await resp.json()
                    if (json.status !== "ok")
                        return Promise.reject(json)
                    return undefined
                } catch {
                    if (resp.ok)
                        return undefined
                    else
                        return Promise.reject({message: resp.statusText})
                }
            })
            .then(() => navigate("/reload", { replace: true }))
            .then(() => postEvent({ eventType: 'team-login', realm: team, user }))
            .catch(err => { console.warn(err); setErrorMessage(err.message ?? err.payload) })
    }

    return (
        <div className="w-fit">
            <h3 className="pb-1">Manage Team</h3>
            <div className="py-1 flex flex-row gap-2 min-w-sm"> 
                <select 
                        id="team"
                        className={"form-select w-full min-w-fit border border-1 pl-2 p-px h-10"}
                        value={team}
                        onChange={(e) => { setTeams(e.target.value) }}
                >
                    <option key="select-user-key" value="">Select a Team</option>
                    {_.sortBy(
                        _.map(teams, t => ({ ...t, show: t.displayName ? `${t.displayName} (${t.name})` : t.name })),
                        r => r.show.toLowerCase()
                    ).map(r => <option key={r.name} value={r.name}>{r.show}</option>)}
                </select>
                <button className="" onClick={() => setTeams("")}><IconX /></button>
                <button className="btn-tertiary text-sm whitespace-nowrap" onClick={() => setTeams(myTeam)}>Pick current team {myTeam}</button>
            </div>
            {team !== ""
                && <div>
                    <TeamManagementProvider {...{team, triggerReload}}>
                        <div className="p-2 bg-pc-100 divide-y-2 divide-pcx-300">
                            <div className="flex flex-row gap-8 pb-4 mb-2">
                                <TeamActivation {...teams.find(t => t.name === team)}/>
                                <div>
                                    <h4 className="mb-2">Team Settings</h4>
                                    <SingleTeamSettings realm={team} />
                                </div>
                                <TeamFlags team={team} />
                            </div>
                            <div className="py-4">
                                <h4 className="mb-3">Log into Team &quot;{team}&quot;</h4>
                                <button className="btn-primary" onClick={() => teamLogin()}>Login</button>

                            </div>
                            <div> <UserTable withHeader/> </div>
                            <div className="pt-2 w-fit gap-6 lg:columns-2">
                                <CreateTeamUser withHeader />
                                <div className="lg:hidden h-px bg-pcx-300" />
                                <AddUser withHeader existingUsers={users} />
                                {/* TODO Invite user via email */}
                            </div>
                            <AdminDmsSettings {...{team}} />
                        </div>
                    </TeamManagementProvider>
                </div>
            }
        </div>
    )
}


// NOTE: flags should be at most 16 characters
const teamFlags = [
    { value: annuities_flag, label: "Has Annuities?" },

    { value: claim_scope_flag, label: "Has Claim Scopes?" },
    { value: product_country_flag, label: "Has Product Country?" },
    { value: portfolio_update_flag, label: "Has Portfolio Update?" },

    { value: excel_import_flag, label: "Has Excel Import?" },
    { value: excel_export_flag, label: "Has Excel Export?" },
    { value: tasks_flag, label: "Has Tasks?" },
    { value: costs_flag, label: "Has Costs?" },

    { value: brand_flag, label: "Has Trademarks?" },
    { value: invention_flag, label: "Has Innovations?" },
    { value: new_innovation_flag, label: "Has New Foreign Countries (Innovations)?" },
    { value: documents_flag, label: "Has Documents/Tabs?" },

    { value: prio_sort_flag, label: "Has Priority Sorting?" },
]

const userFlags = [
    ...teamFlags,
    // user specific flags
    { value: annuities_admin_flag, label: "Is Annuity Admin?" },
    { value: "dev", label: "Is Dev?" },
    { value: "super-admin", label: "Is Super Admin?" },
    { value: tracking_flag, label: "Do tracking?" },
]

function TeamFlags({team}: {team: string}) {
    const {flags: originalFlags, addFlags, removeFlags} = useTeamFlags(team)

    return <div>
        <h4>Team Flags</h4>

            <Formik 
                initialValues={{flags: originalFlags}}
                onSubmit={async ({flags}, {setSubmitting, resetForm}) => {
                    const [toAdd, toDelete] = linkDifference(originalFlags ?? [], flags)

                    //console.log({toAdd, toDelete})

                    await addFlags(toAdd)
                    await removeFlags(toDelete)

                    setSubmitting(false)
                    resetForm({values: {flags}})
                }}
                enableReinitialize
            >{({ dirty }) => {
                const disabled = !dirty
                return (
                    <Form className="max-w-sm flex flex-col gap-2 py-2">
                        {teamFlags.map(({value, label}) => (
                            <label key={value}>
                                <Field className="form-checkbox" type="checkbox" name="flags" {...{value}} />
                                <span className="pl-2">{label}</span>
                            </label>
                        ))}

                        <div className="flex flex-row-reverse">
                            <button 
                                className={disabled ? "btn-disabled" : "btn-primary"} 
                                type="submit" {...{disabled}}
                            >
                                Update
                            </button> 
                        </div>
                    </Form>
                )
            }}
            </Formik>
    </div>
}

function PlanField() {
    return <>
        <label htmlFor="plan">Plan</label>
        <Field className="form-select" name="plan" id="plan" as="select">
            {plans.map(p => <option key={p} value={p}>{p}</option>)}
        </Field>
    </>
}

function TeamActivation({name, active, plan}: {name: string, active?: boolean, plan?: string}) {
    const {updateTeam} = useAdminManagementContext()
    const initialValues = {active, plan}
    return (
        <div className={areaStyling}>
            <h4 className="mb-2">Team Activation</h4>
            {name && 
                <Formik
                    key={name}
                    initialValues={initialValues}
                    onSubmit={(team, {resetForm}) => 
                        updateTeam({...team, name})
                            .then(() => resetForm({values: team}))
                    }
                >{({dirty, resetForm}) =>
                    <Form className="flex flex-col gap-2 pb-2">
                        <label className="inline-flex gap-2 items-center">Active <Field className="form-checkbox" name="active" type="checkbox" />
                        </label>
                        <PlanField />
                        <div className="flex flex-row-reverse gap-2">
                            <input disabled={!dirty} className="disabled:btn-disabled btn-primary" type="submit" value="Update" />
                            <button className="btn-secondary" onClick={() => resetForm({values: initialValues})}>Reset</button>
                        </div>
                    </Form>
                }</Formik>
            }
        </div>
    )
}

function SetPassword({user}) {
    const {setErrorMessage} = useMessages()

    return (
        <div className={areaStyling}>
            <h4>Set Password for {user}</h4>
            <Formik
                initialValues={{ password: '' }}
                onSubmit={({ password }, { resetForm }) =>
                    updatePassword(user, password)
                        .then(() => resetForm())
                        .catch(err => setErrorMessage(err.message))
                }
            >{({ values: { password } }) => {
                const submittable = password.length >= 4
                return (
                    <Form className="flex flex-col gap-1 sm:w-full" >
                        <label htmlFor="password" className={labelStyle}>New Password</label>
                        <Field id="password" name="password" className="form-input" autoComplete="off" type="password" pattern="[\x00-\x7F]+" />
                        <div className="flex flex-row-reverse pt-2">
                            <button type="submit" className={submittable ? "btn-primary" : "btn-disabled"}>Save</button>
                        </div>
                    </Form>
                )
            }}</Formik>
        </div>
    )
}

function UserInfo({user}) {
    const {userSettings} = useUserSettingsFor(user)
    const email = userSettings?.email
    return (
        <div className={areaStyling}>
            <h4>User Info</h4>
            <dl>
                <dt className="label float-left pr-2">Email:</dt>
                <dd>{email ? <a className="underline text-pcx-600" href={`mailto:${email}`}>{email}</a> : '-'}</dd>
            </dl>
        </div>
    )
}


function DeleteUser({user, deleteUser}) {
    const {setErrorMessage} = useMessages()

    const [showConfirm, setShowConfirm] = useState(false)

    function handleSubmit() {
        deleteUser(user).catch((err) => setErrorMessage(err.message))
    }

    return (
        <div className={areaStyling}>
            <h4>Delete User {user}</h4>
            <form
                onSubmit={(e) => { e.preventDefault() }}
                className="flex flex-col space-y-1 max-w-sm">
                <div className="pt-2 flex flex-row justify-end">
                    <button
                        onClick={() => setShowConfirm(true)}
                        className={"btn-warn max-w-fit"}
                    >
                        Delete User {user}
                    </button>
                </div>
                {showConfirm &&
                    <div className="p-1 pt-2">
                        <div className="pb-1">
                            Really delete User {user}?
                        </div>
                        <div className="flex flex-row justify-between">
                            <button
                                onClick={() => setShowConfirm(false)}
                                className="hover:bg-sky-300 bg-sky-100 border-sky-300 py-1 px-2 max-w-fit rounded-sm border"
                            >
                                No, abort
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="hover:bg-orange-300 bg-orange-100 border-orange-300 py-1 px-2 max-w-fit rounded-sm border"
                            >
                                Yes, delete User {user}
                            </button>
                        </div>
                    </div>}
            </form>
        </div>
    )
}

function UserFlags({user, flags: originalFlags, setHasLoaded}: {user: string, flags: string[], setHasLoaded: (b: boolean) => void}) {
    const { setErrorMessage } = useMessages()

    function manageFlag(operation: string, username: string, flag: string) {
        return management({type: "flag", operation, username, flag})
    }

    return (
        <div className={areaStyling}>
            <h4>User Flags for {user}</h4>
            <div className="font-semibold p-2 text-warn-800 border border-warn-600 rounded mt-2">Ask yourself: Would it be better to set flags on the team level?</div>

            <Formik 
                initialValues={{flags: originalFlags}}
                onSubmit={({flags}, {setSubmitting, resetForm}) => {
                    const [toAdd, toDelete] = linkDifference(originalFlags ?? [], flags)

                    Promise.all(toAdd.map(f => manageFlag("add", user, f)))
                        .then(() => Promise.all(toDelete.map(f => manageFlag("delete", user, f))))
                        .then(() => setHasLoaded(false))
                        .catch(err => setErrorMessage(err.message))

                    setSubmitting(false)
                    resetForm({values: {flags}})
                }}
            >{({ dirty }) => {
                const disabled = !dirty
                return (
                    <Form className="max-w-sm flex flex-col gap-2 py-2">
                        {userFlags.map(({value, label}) => (
                            <label key={value}>
                                <Field className="form-checkbox" type="checkbox" name="flags" {...{value}} />
                                <span className="pl-2">{label}</span>
                            </label>
                        ))}

                        <div className="flex flex-row-reverse">
                            <button 
                                className={disabled ? "btn-disabled" : "btn-primary"} 
                                type="submit" {...{disabled}}
                            >
                                Update
                            </button> 
                        </div>
                    </Form>
                )
            }}
            </Formik>
        </div>
    )
}
