import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import _ from 'lodash'

import { useBackend } from '../BackendProvider'
import { patent_family } from '../data'
import { Tag } from '../patents/patents'
import { usePatents } from '../patents/PatentsProvider'
import { useProducts } from '../products/ProductsProvider'

export default function TagsManagement() {
    const {t} = useTranslation()
    const {familyById} = usePatents()
    const {tags, linkOperation} = useBackend()
    const {commodityById} = useProducts()

    function partitionTags(tags: Tag[], entity: string, lookup: Record<string, any> = {}) {
        return _(tags)
            .filter(t => t.entity === entity)
            .partition(t => t.entityId in lookup)
            .value()
    }

    const byTag = _(tags)
        .groupBy('tag')
        .toPairs()
        .sortBy(([tag]) => tag.toLowerCase())
        .map(([tag, tags]) => {
            const [existingFamilyTags,  deletedFamilyTags] = partitionTags(tags, patent_family, familyById)
            const [existingProductTags, deletedProductTags] = partitionTags(tags, "commodity", commodityById)
            return {
                tag,
                tags,
                existingFamilyTags,  deletedFamilyTags,
                existingProductTags, deletedProductTags,
            }
        })
        .value()

    async function removeUnlinkedTags(row: TagRowProps) {
        await linkOperation('tag', 'bulk-delete', row.unlinkedFamilyTags)
        await linkOperation('tag', 'bulk-delete', row.unlinkedProductTags)
        await linkOperation('tag', 'get')
    }

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('tags')} | {t('settings')} | Patent Cockpit</title>
        </Helmet>
        <div className="flex flex-row gap-2 header-row">
            <h2 className="pt-1 modern-h2">{t('tags')}</h2>
        </div>
        <div className="main-content bg-pcx-100 pt-2">
            <div className='bg-white p-4 rounded-xl shadow w-fit'>
                <table>
                    <thead>
                        <tr className='border-b-2 border-pcx-300'>
                            {['tag', 'total', 'families', 'deletedFamilies', 'commodities', 'deletedCommodities'].map(header =>
                                <th key={header} className="px-2 lg:px-3 py-1 pcx-500 text-left text-pcx-600 text-xs whitespace-nowrap font-semibold uppercase tracking-wider">
                                    {t(header)}
                                </th>
                            )}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {_(byTag)
                            .map(({ tag, ...rest }: TagRowProps & {tag: string}) => {
                                return (
                                    <tr key={tag} className=''>
                                        <td className='px-2 lg:px-3 py-1'><h3 className='font-medium text-pcx-600'>{tag}</h3></td>
                                        {['tags',
                                            'existingFamilyTags',  'deletedFamilyTags',
                                            'existingProductTags', 'deletedProductTags',
                                        ].map(key => {
                                            const count = rest[key]?.length ?? 0
                                            const hasUnlinked = key.startsWith('deleted') && count > 0
                                            return (
                                                <td key={key} className={clsx(
                                                    'px-3 text-right tabular-nums',
                                                    hasUnlinked && 'text-red-700 font-semibold peer'
                                                )}>
                                                    {count}
                                                </td>
                                            )
                                        })}
                                        <td className='flex p-1'>
                                            {/*<button className='btn-warn'>Remove All</button>*/}
                                            <button 
                                                className='btn-secondary text-xs whitespace-nowrap'
                                                onClick={() => removeUnlinkedTags(rest)}
                                            >
                                                {t('removeInactive')}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            .value()}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

interface TagRowProps {
    tags: Tag[],
    linkedFamilyTags: Tag[],
    unlinkedFamilyTags: Tag[],
    linkedProductTags: Tag[],
    unlinkedProductTags: Tag[],
}