import _ from 'lodash'
import { emptyStringAsUndefined } from '../utils/strings';

export type Agent = {
    agentType: string;
    agentId?: number;
    firstName?: string;
    lastName?: string;
    name?: string;
}

export type AgentLink = {
    linkType: string;
    familyMemberId: number;
    agentType: string;
    agentId: number;
}

export function nameOf(agent: Agent) {
    return agent.agentType === "company"
        ? agent.name
        : `${agent.firstName} ${agent.lastName}`
}

export function splitCompanyName(agent: Agent) {
    const parts = agent.name.split(' ')
    return { firstName: parts[0], lastName: parts.slice(1).join(' ')}
}

export function createAgentsLookup(agentLinks: AgentLink[]): Record<string, Record<number, number[]>> {
    return  _(agentLinks)
        .groupBy(l => l.linkType)
        .mapValues(ls => _(ls)
            .groupBy(l => l.agentId)
            .mapValues(vs => vs.map(v => v.familyMemberId))
            .value())
        .value()
}

export function createAgentsByMemberId(agentLinks: AgentLink[]): Record<string, Record<number, number[]>> {
    return _(agentLinks)
        .groupBy(l => l.linkType)
        .mapValues(ls => _(ls)
            .groupBy(l => l.familyMemberId)
            .mapValues(vs => vs.map(v => v.agentId))
            .value())
        .value()
}

export function asPerson(name?: string): Agent | undefined {
    const names = name?.trim()?.split(' ')?.filter(w => w !== '')
    if (names?.length === 2) {
        return { agentType: 'person', firstName: names[0], lastName: names[1] }
    } else {
        return undefined
    }
}

export function asCompany(_name?: string): Agent | undefined {
    const name = emptyStringAsUndefined(_name?.trim())
    if (name) {
        return { agentType: 'company', name }
    } else {
        return undefined
    }
}

export function isEquivalentAgent(a: Agent, b: Agent) {
    if (a.agentType === "person" && b.agentType === "person")
        return a.firstName === b.firstName && a.lastName === b.lastName
    else if (a.agentType === "company" && b.agentType === "company")
        return a.name === b.name
    else
        return false
}